//Import
import React from "react";
import styled from "styled-components";
import { IRow } from "../Grid";
import ModalButton from "../ModalButton/ModalButton";
import ModalButtonCrear from "../ModalButtonCrear/ModalButtonCrear";
import styles from "./ModalFooter_styles";

// Componente base
const ModalFooter_base = ({ closeModal, ...props }) => {
  return (
    <div {...props}>
      <IRow justify="flex-end">
        <ModalButton cancel closeModal={closeModal}>
          {" "}
          Cancelar{" "}
        </ModalButton>
        <ModalButtonCrear>Crear</ModalButtonCrear>
      </IRow>
    </div>
  );
};
const ModalFooter = styled(ModalFooter_base)`
  ${styles}
`;
export default ModalFooter;
