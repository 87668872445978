import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    
    button{
        font-size:0.82em;
        color:${ofc.textColor};
        padding:1em 1.2em;
        &.ok-button{
            color: ${ofc.primary};
            font-weight:bold;}
        }

`

export default styles
    