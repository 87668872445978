import { css } from 'styled-components'
import ofc from '../../settings/variables'


const styles = css`
    label{
        display: block;
        padding: 0.6em 0;
        font-size:${ofc.x0};
        font-weight:bold;
    }
    input{
        border-radius:${ofc.s1}em;
        padding:0.8em;
        font-size:${ofc.x0};
        color:${ofc.textColor};
        background-color:${ofc.bgLight};
        border: 1px solid ${ofc.bgDark};

    }
`

export default styles
    