
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './NavContainer_styles'

// Components
import { IRow } from '../Grid'

// Componente base
const NavContainer_base = ({...props}) => {
    return (
        <div {...props}>
            <IRow justify='center' >
                { props.children }
            </IRow>
        </div>
    )
}
const NavContainer = styled(NavContainer_base)`${ styles }`
export default NavContainer
