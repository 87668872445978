import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
  .inputContainer {
    position: relative;
    padding: 30px 0 1px 0;
    overflow: hidden;
    margin-top: 1em;

    .label {
      position: absolute;
      bottom: 5px;
      color: rgba(108, 108, 108, 0.52);
      z-index: 21;
      left: 5px;
      font-size: 11px;
      transform: translateY(0px);
      transition: transform 0.2s cubic-bezier(0, 0, 0, 1);
    }

    .ofc-input {
      border-radius: 0.1em;
      padding: 0.5em;
      font-size: ${ofc.x1};
      font-weight: bold;
      color: #000000;
      background-color: #fff;
      border: 1px solid ${ofc.inputBorder};
      position: relative;
      &:focus,
      &:not(:placeholder-shown) {
        outline: none;
        box-shadow: none;
        & + .label {
          transform: translateY(-48px);
          color: ${ofc.primary};
        }
      }
      &:focus ~ .bar {
        transform: translateX(0);
      }
    }

    .bar {
      position: absolute;
      z-index: 22;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: ${ofc.primary};
      transform: translateX(-100%);
      transition: transform 0.3s cubic-bezier(0, 0, 0, 1);
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export default styles
