import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    position: relative;

    .val{
        border:1px solid ${ofc.bgDark};
        border-radius:${ofc.s1}em;
        background-color: ${ofc.bgLight};
        font-weight: 700;
        font-size:${ofc.font_size};
        color:${ofc.bgDark};
        display:block;
        width:100%;
        padding:0.4em;
    }

    .submit{
        position: absolute;
        top:calc(50% - 1em);
        right:${ofc.xm3};
        background-image:url(./img/svg/money_green.svg);
        background-size:contain;
        background-position: center center;
        //background-color:red;

        &[type='submit']{
            color: transparent;
            background-color: transparent;
        }
 
        width:${ofc.x3};
        height:${ofc.x3};
    }
`

export default styles
    