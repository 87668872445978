
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './Avatar_styles'

/**
 *  Props
 *  img: String = "Show image" -> Required
 *  alt: String = Sets alternative text for image -> Optional
 *  main: Boolean = True: Show Main Avatar
 *  office: String = User Office 
 */


// Componente base
const Avatar_base = ({img, alt, main, office, ...props}) => {
    return (
        <div {...props}>
            <div className={ main ? 'mainAvatar' : ''}>
                <figure>
                    <img src={img && img.length > 0 ? img : './img/basic.png' } alt={ alt ? alt : 'User Profile' } />
                </figure>
                
                { main
                    ? <span className='avatarCaption'>{ office }</span>
                    : ''
                }
            </div>
        </div>
    )
}
const Avatar = styled(Avatar_base)`${ styles }`
export default Avatar
