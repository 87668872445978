//Import
import React from "react";

import styled from "styled-components";
import styles from "./HomeButton_styles";

/**
 * Props
 * submit:Boolean -> if true render an input type submit false is a button tag
 */

// Componente base
const HomeButton_base = ({ submit, ...props }) => {
  return (
    <div {...props}>
      {!submit ? (
        <button className="transparent-button entrar">
          {" "}
          {props.children}{" "}
        </button>
      ) : (
        <input
          className="transparent-button"
          type="submit"
          value={props.children}
        />
      )}
    </div>
  );
};
const HomeButton = styled(HomeButton_base)`
  ${styles}
`;
export default HomeButton;
