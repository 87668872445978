import { types } from "../types/types";

export const gastosInitialState = {
  db: null,
  totalGastos: 0,
  copiaDb: null,
};

export const gastosReducer = (state = {}, action) => {
  switch (action.type) {
    case types.gastos_todos: {
      return {
        ...state,
        db: action.payload.map((data) => data),
        copiaDb: action.payload.map((data) => data),
        totalGastos: action.payload.reduce(
          (prevValue, currentValue) => prevValue + parseInt(currentValue.valor),
          0
        ),
      };
    }

    case types.gastos_eliminar: {
      return {
        ...state,
        db: state.db.filter((item) => item.id !== action.payload.id),
        totalGastos: state.totalGastos - action.payload.valor,
        copiaDb: state.copiaDb.filter((item) => item.id !== action.payload.id),
      };
    }

    case types.gastos_buscar: {
      let busqueda = action.payload.toLocaleLowerCase();
      let resultado = state.copiaDb.filter((item) => {
        if (
          item.descripcion.toLocaleLowerCase().includes(busqueda) ||
          item.gasto_padre.nombre.toLocaleLowerCase().includes(busqueda)
        ) {
          return item;
        }
      });
      return {
        ...state,
        db: resultado,
        totalGastos: resultado.reduce(
          (prevValue, currentValue) => prevValue + parseInt(currentValue.valor),
          0
        ),
      };
    }

    case types.gastos_limpiar_filtro: {
      return {
        ...state,
        db: state.copiaDb,
        totalGastos: state.copiaDb.reduce(
          (prevValue, currentValue) => prevValue + parseInt(currentValue.valor),
          0
        ),
      };
    }

    case types.gastos_crear: {
      return {
        ...state,
        db: [action.payload, ...state.db],
        copiaDb: [action.payload, ...state.db],
        totalGastos: state.totalGastos + parseInt(action.payload.valor),
      };
    }

    default:
      return state;
  }
};
