import { css } from 'styled-components'
import ofc from '../../settings/variables'

const styles = css`

    font-size:${props =>  props.fontsize || ofc.xm1 } ;
    color: #fff;
    text-align:${props => props.align || 'left'};

`

export default styles
    