import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    position:relative;
    width:100%;
    .bar{
        position:absolute;
        width:100%;
        transform: scale(${props => props.value / 100 || 1 },1);
        transform-origin:0 0;
        height:2px;
        background-color: ${ofc.primary} ;
    }
`

export default styles
    