//Import
import React, { useEffect, useReducer, useState } from "react";

//Layouts
import GroupHeader from "../../components/GroupHeader";
import GroupTableContainer from "../../components/GroupTableContainer";
import GroupItemVer from "../../components/GroupItemVer";
import GroupTableHeader from "../../components/GroupTableHeader";
import NavBar from "../../components/NavBar";

//vars
import ofc from "../../settings/variables";
import { URL_BASE_API } from "../../settings/urlBaseApi";
import {
  verAbonosInitialState,
  verAbonosReducer,
} from "../../reducers/verAbonosReducer";
import { helpHttp } from "../../helpers/helpHtpp";
import Loader from "../../components/Loader/Loader";
import Mensaje from "../../components/Mensaje/Mensaje";
import { types } from "../../types/types";
import { Modal as ModalAnt } from "antd";

import { message } from "antd";
import { formatNumber } from "../../helpers/formatNumber";
import ModalVerAbonos from "../../components/ModalVerAbonos/ModalVerAbonos";

// Componente base
const VerAbonosView = ({ ...props }) => {
  const [state, dispatch] = useReducer(verAbonosReducer, verAbonosInitialState);
  const { db, totalAbonos } = state;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalDetalleVisible, setModalDetalleVisible] = useState(false);
  const [dataModalDetalle, setDataModalDetalle] = useState("");
  const [dataModalDetalleAbonos, setDataModalDetalleAbonos] = useState("");

  const closeModalDetalle = () => {
    setModalDetalleVisible(false);
  };

  const handleClickDetalle = (el) => {
    cargarAbonosCredito(el.credito_id);
    setDataModalDetalle(el);
    setModalDetalleVisible(true);
  };

  const cargarAbonosCredito = (creditoId) => {
    let urlAbonosCreditos =
      URL_BASE_API + "/cuotas-creditos/abonos/" + creditoId;
    setLoading(true);

    helpHttp()
      .get(urlAbonosCreditos)
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null);
            setDataModalDetalleAbonos(res);
          } else {
            setError(res);
          }
        }
      });

    setLoading(false);
  };

  const filtroBusqueda = ({ target }) => {
    dispatch({
      type: types.ver_abonos_buscar,
      payload: target.value,
    });
  };

  const limpiarFiltroBusqueda = () => {
    dispatch({
      type: types.ver_abonos_limpiar_filtro,
    });
  };

  const handleClickEliminar = (e, el) => {
    e.preventDefault();
    ModalAnt.confirm({
      title: `Eliminar abono`,
      content: `¿Está seguro de eliminar el abono del cliente ${
        el.cliente.nombre_completo
      } por valor de ${formatNumber(el.valor_cuota)}`,
      cancelText: "Cancelar",
      okText: "Eliminar",
      onOk: () => eliminarAbono(el),
    });
  };

  const eliminarAbono = (el) => {
    let url = URL_BASE_API + "/abonos/" + el.id;
    setLoading(true);
    helpHttp()
      .del(url)
      .then((res) => {
        if (!res.err) {
          if (res instanceof TypeError) {
            message.error({
              content: `Abono del cliente ${
                el.cliente.nombre_completo
              } por valor de ${formatNumber(
                el.valor_cuota
              )} no fue eliminado, intentelo de nuevo!`,
            });
          } else {
            setError(null);
            dispatch({
              type: types.ver_abonos_eliminar,
              payload: el,
            });
            message.success({
              content: `Abono del cliente ${
                el.cliente.nombre_completo
              } por valor de ${formatNumber(el.valor_cuota)} fue eliminado!`,
            });
          }
        } else {
          message.error({
            content: `Hubo un error, el abono del cliente ${
              el.cliente.nombre_completo
            } por valor de ${formatNumber(el.valor_cuota)} no fue eliminado!`,
          });
        }
        setLoading(false);
      });
  };

  let url = URL_BASE_API + "/abonos";
  useEffect(() => {
    setLoading(true);
    helpHttp()
      .get(url)
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null);
            dispatch({
              type: types.ver_abonos_todos,
              payload: res,
            });
          } else {
            setError(res);
          }
        }
        setLoading(false);
      });
  }, [url]);

  return (
    <div {...props}>
      <GroupHeader
        category="Ver Abonos"
        filtroBusqueda={filtroBusqueda}
        limpiarFiltroBusqueda={limpiarFiltroBusqueda}
      />
      <ModalVerAbonos
        type="bottom"
        show={modalDetalleVisible}
        opacity="0.8"
        w={"15em"}
        closeModalDetalle={closeModalDetalle}
        dataModalDetalle={dataModalDetalle}
        dataModalDetalleAbonos={dataModalDetalleAbonos}
      ></ModalVerAbonos>
      <GroupTableContainer>
        <GroupTableHeader section="Ver Abonos" />
        {db !== null ? (
          db.map((el) => (
            <GroupItemVer
              key={el.id}
              username={el.cliente.nombre_completo}
              userdebt={formatNumber(el.saldo_actual)}
              uservalue={formatNumber(el.valor_cuota)}
              iconcolor={
                el.estado === 1
                  ? ofc.primary
                  : el.estado === 3
                  ? ofc.yellow
                  : ofc.textColor
              }
              valuecolor="blue"
              fechaCredito={el.credito.fecha}
              prestado={formatNumber(el.credito.capital)}
              aldia={formatNumber(
                el.cuotacredito.valor_cuota - el.valor_cuota > 0
                  ? el.cuotacredito.valor_cuota - el.valor_cuota
                  : 0
              )}
              handleClickEliminar={(e) => handleClickEliminar(e, el)}
              textoEliminar="Abono"
              handleClickDetalle={(e) => handleClickDetalle(el)}
              mostrarSaldo={true}
              estadoAbono={el.estado}
            />
          ))
        ) : (
          <Mensaje
            mensaje="No hay información para mostrar"
            type="info"
            title="Información"
          />
        )}
        {error && (
          <Mensaje
            mensaje={`Error ${error.status}: ${error.statusText}`}
            type="error"
            title="Error"
          />
        )}
      </GroupTableContainer>
      {loading && <Loader />}
      <NavBar
        texto1="No. Abonos"
        texto2="Total Abonos"
        totalTexto1={
          db != null ? db.filter((abono) => abono.valor_cuota > 0).length : 0
        }
        totalTexto2={formatNumber(totalAbonos)}
        view="ver-abonos"
      />
    </div>
  );
};
export default VerAbonosView;
