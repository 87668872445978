import { Result, Button } from "antd";
import { useParams } from "react-router";

const mensajes = [
  {
    estado: "no-autorizado",
    status: 403,
    title: "No Autorizado",
    subtitle: "Lo sentimos, no estas autorizado para estar aquí.",
  },
  {
    estado: "ruta-cerrada",
    status: 403,
    title: "Ruta Cerrada",
    subtitle:
      "Lo sentimos, la ruta se encuentra cerrada podra volver ingresar el siguiente día",
  },
  {
    estado: "no-encontrado",
    status: 404,
    title: "No encontrado",
    subtitle: "Lo sentimos, la página que estas intentando visitar no existe.",
  },
  {
    estado: "error-servidor",
    status: 500,
    title: "Error Servidor",
    subtitle: "Lo sentimos, algo ha fallado",
  },
];

const ErrorView = ({ history }) => {
  const { estado } = useParams();
  const handleClickAtras = () => {
    window.location = "/#/abonos";
  };
  const index = mensajes.map((mensaje) => mensaje.estado).indexOf(estado);
  return (
    <>
      <Result
        status={mensajes[index].status}
        title={mensajes[index].title}
        subTitle={mensajes[index].subtitle}
        extra={
          <Button onClick={handleClickAtras} type="primary">
            Página Principal
          </Button>
        }
      />
      ,
    </>
  );
};

export default ErrorView;
