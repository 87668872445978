//Import
import React from "react";
import styled from "styled-components";
import styles from "./GroupItemGen_styles";

//Components
import { ICol, IRow } from "../Grid";
import ItemContainer from "../ItemContainer";
import FilledIcon from "../FilledIcon";
import ItemDelete from "../ItemDelete";
import ItemValue from "../ItemValue/ItemValue";
import RowTextCredito from "../RowTextCredito/RowTextCredito";

/**
 * Props
 * userdebt:String
 * iconcolor:String (HEX) Define icon color, for consistency, user variables file to set the icon
 * uservalue:String Sets the user value item
 * valuecolor:String Set the price color
 *
 */

// Componente base
const GroupItemGen_base = ({
  username,
  userdebt,
  iconcolor,
  uservalue,
  valuecolor,
  icon,
  texto,
  handleClickEliminar,
  textoEliminar,
  handleClickDetalle,
  cargaAutomaticaCreditos,
  mostrarSaldo,
  ...props
}) => {
  return (
    <ItemContainer>
      <IRow valign="center" justify={"space-around"} py={1} px={1.2}>
        <ICol wf={2}>
          <FilledIcon icon="bag" color={iconcolor} />
        </ICol>

        <ICol w={35}>
          <RowTextCredito
            handleClickDetalle={handleClickDetalle}
            username={username}
            debt={userdebt}
          />
        </ICol>

        <ICol wf={6}>
          <ItemValue color={valuecolor}> {uservalue}</ItemValue>
        </ICol>

        {/*<ICol wf={2.5}>
          <IconNav
            icon={icon}
            text={texto}
            to="#"
            size={"1.3em"}
            fontsize={"0.7em"}
            handleClickDetalle={handleClickDetalle}
          />
  </ICol>*/}
        {cargaAutomaticaCreditos !== 0 ? (
          <ICol wf={2} px={0.6}>
            <ItemDelete
              handleClickEliminar={handleClickEliminar}
              textoEliminar={textoEliminar}
            />
          </ICol>
        ) : (
          ""
        )}
      </IRow>
    </ItemContainer>
  );
};
const GroupItemGen = styled(GroupItemGen_base)`
  ${styles}
`;
export default GroupItemGen;
