import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    padding:0.4em 0;
    height:36px;

    //Overwriting ant design base color
    .ant-switch-checked {
        background-color:${ofc.primary} ;
    }

`

export default styles
    