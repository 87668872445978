//Import
import React from "react";
import styled from "styled-components";
import styles from "./GroupItem_styles";

//Components
import { ICol, IRow } from "../Grid";
import ItemContainer from "../ItemContainer";
import FilledIcon from "../FilledIcon";
import RowText from "../RowText";
import RowInput from "../RowInput";
import FilledIconFunction from "../FilledIconFunction/FilledIconFunction";
import FilledIconGps from "../FilledIconGps";
//vars

/**
 * DATA PROPS
 * RUTA=>
 * inputvalue
 * username
 * userdebt
 * iconcolor
 *
 * STYLE PROPS:
 * ruta: String Shows the component as prop name
 * abonos: String Shows the component as prop name
 * gastos: String Shows the component as prop name
 * clientes: String Shows the component as prop name
 * cierres: String Shows the component as prop name
 *
 */

// Componente base
const GroupItem_base = ({
  inputvalue,
  username,
  userdebt,
  iconcolor,
  handleClickDetalle,
  handleClickRegistrarAbono,
  textoAbono,
  mostrarSaldo,
  fechaCredito,
  aldia,
  prestado,
  usarGpsAsesor,
  latitud,
  longitud,
  handleClickActualizarUbicacionCredito,
  handleClickIrUbicacion,
  ...props
}) => {
  return (
    <ItemContainer>
      <IRow valign="center" justify={"center"} py={1} px={0.1}>
        <ICol wf={2}>
          {usarGpsAsesor === "true" ? (
            latitud !== "0" && longitud !== "0" ? (
              <FilledIconGps
                icon="gps"
                color={iconcolor}
                handleClickIrUbicacion={handleClickIrUbicacion}
              />
            ) : (
              <FilledIconFunction
                icon="close"
                handleClickActualizarUbicacionCredito={
                  handleClickActualizarUbicacionCredito
                }
                color={iconcolor}
              />
            )
          ) : (
            <FilledIcon icon="bag" color={iconcolor} />
          )}
        </ICol>

        <ICol w={45}>
          <RowText
            handleClickDetalle={handleClickDetalle}
            username={username}
            debt={userdebt}
            mostrarSaldo={mostrarSaldo}
            fechaCredito={fechaCredito}
            prestado={prestado}
            aldia={aldia}
          />
        </ICol>

        <ICol wf={8}>
          <RowInput
            textoAbono={textoAbono}
            handleClickRegistrarAbono={handleClickRegistrarAbono}
            inputvalue={inputvalue}
          >
            {" "}
          </RowInput>
        </ICol>
      </IRow>
    </ItemContainer>
  );
};
const GroupItem = styled(GroupItem_base)`
  ${styles}
`;
export default GroupItem;
