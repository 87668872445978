//Import
import React, { useState } from "react";
import styled from "styled-components";
import styles from "./RowInputEnrutar_styles";

/**
 * PROPS
 * submit:Boolean = Define if input behavior is based on submit or button
 * inputvalue:string = Sets Base Value to input type text
 */

// Componente base
const RowInputEnrutar_base = ({
  handleClickEnrutarSave,
  submit,
  inputvalue,
  textoAbono,
  ...props
}) => {
  const [val, newValue] = useState(inputvalue);

  return (
    <div {...props}>
      <input
        type="number"
        className="val"
        value={val}
        onChange={(event) => newValue(event.target.value)}
        autoComplete="off"
      />
      {
        <input
          onClick={(e, prioridad, el) => handleClickEnrutarSave(e, val, el)}
          className="submit transparent-button"
          type={submit ? "submit" : "button"}
          value=""
          autoComplete="off"
        />
      }
    </div>
  );
};
const RowInputEnrutar = styled(RowInputEnrutar_base)`
  ${styles}
`;
export default RowInputEnrutar;
