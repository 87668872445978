
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './HomeContainer_styles'

// Componente base
const HomeContainer_base = ({...props}) => {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}
const HomeContainer = styled(HomeContainer_base)`${ styles }`
export default HomeContainer
