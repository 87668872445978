import { css } from "styled-components";
import ofc from "../../settings/variables";

const styles = css`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, ${(props) => props.opacity || "0.5"});
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  //OverWrite tabs Styles
  .ow-tabs {
    color: ${ofc.textColor};
    overflow-y: auto;
    height: 400px;
  }
  .ow-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${ofc.primary};
  }

  .ow-tabs .ant-tabs-ink-bar {
    background-color: ${ofc.primary};
  }
`;

export default styles;
