//Import
import React, { useEffect, useReducer, useState } from "react";

// Component Composition
import GroupHeader from "../../components/GroupHeader";
import GroupTableHeader from "../../components/GroupTableHeader";
import GroupTableContainer from "../../components/GroupTableContainer";
import GroupFormContainer from "../../components/GroupFormContainer";
import NavBar from "../../components/NavBar";

import {
  cierresRutasReducer,
  cierresRutasInitialState,
} from "../../reducers/cierresRutasReducer";
import { URL_BASE_API } from "../../settings/urlBaseApi";
import { Modal as ModalAnt } from "antd";
import { deleteToken, getToken, helpHttp } from "../../helpers/helpHtpp";
import { types } from "../../types/types";
import Mensaje from "../../components/Mensaje/Mensaje";
import Loader from "../../components/Loader/Loader";
import { message } from "antd";
import ModalPdfCierre from "../../components/ModalPdfCierre/ModalPdfCierre";

// Componente base
const CierresView = ({ ...props }) => {
  const [state, dispatch] = useReducer(
    cierresRutasReducer,
    cierresRutasInitialState
  );
  const [modalPdfCierreVisible, setModalPdfCierreVisible] = useState(false);
  const [listadoCierres, setListadoCierres] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    totalCobro,
    totalGastos,
    totalCreditos,
    totalDevoluciones,
    ajuste,
    caja,
    totalCierre,
    totalSaldados,
    cajaInicial,
    fechaCajaInicial,
    totalMora,
  } = state;

  const hoy = new Date();

  const handleAplicarCierre = (e) => {
    e.preventDefault();
    ModalAnt.confirm({
      title: `Aplicar Precierre`,
      content: `¿Está seguro de aplicar el precierre para la fecha ${hoy.toLocaleDateString()}, después no podra ingresar más a la empresa hasta el siguiente día?`,
      cancelText: "Cancelar",
      okText: "Aplicar",
      onOk: () => aplicarPrecierre(),
    });
  };

  const handleVerPdfMora = (e) => {
    e.preventDefault();
    ModalAnt.confirm({
      title: `Aplicar Precierre`,
      content: `¿Desea ver el PDF de Mora?`,
      cancelText: "Cancelar",
      okText: "Ver PDF Mora",
      onOk: () => verPdfMora(),
    });
  };

  const verPdfMora = () => {
    const token = getToken();
    let url = URL_BASE_API + "/cierres-rutas/pdf/mora";
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        setError(null);
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          window.open(fileURL);
        });
      } else {
        message.error({
          content: `Hubo un error`,
        });
      }
      setLoading(false);
    });
  };

  const closeModalPdfCierre = (e) => {
    e.preventDefault();
    setModalPdfCierreVisible(false);
  };

  const aplicarPrecierre = () => {
    let url = URL_BASE_API + "/cierres-rutas";
    setLoading(true);
    helpHttp()
      .post(url, {
        body: {
          totalCobro,
          totalGastos,
          totalCreditos,
          totalDevoluciones,
          ajuste,
          caja,
          totalCierre,
          totalSaldados,
        },
      })
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null);
            message.success({
              content: `El precierre para la fecha ${hoy.toLocaleDateString()} fue aplicado!`,
            });
            setTimeout(function () {
              deleteToken();
              window.location = "/#/mensaje-error/ruta-cerrada";
            }, 3000);
          } else {
            message.error({
              content: `Hubo un error, el precierre para la fecha ${hoy.toLocaleDateString()} no fue aplicado!`,
            });
          }
        }
        setLoading(false);
      });
  };

  let url = URL_BASE_API + "/cierres-rutas";
  useEffect(() => {
    setLoading(true);
    helpHttp()
      .get(url)
      .then((res) => {
        if (!res.err) {
          setError(null);
          dispatch({
            type: types.cierres_rutas_todos,
            payload: res,
          });
        } else {
          setError(res);
        }
        setLoading(false);
      });
  }, [url]);

  const handlePdfCierre = () => {
    peticionHttpListadoCierres();
    setModalPdfCierreVisible(true);
  };

  const handleClickVerPdf = (e, cierre) => {
    e.preventDefault();

    ModalAnt.confirm({
      title: `PDF Cierre diario`,
      content: `¿Desea ver el PDF de cierre diario para la fecha ${cierre.fecha}?`,
      cancelText: "Cancelar",
      okText: "Ver PDF",
      onOk: () => handleClickVerPdfPeticion(cierre),
    });
  };

  const handleClickVerPdfPeticion = (cierre) => {
    let url =
      "https://ofinec-cdn.sfo3.digitaloceanspaces.com/" + cierre.soporte;
    setLoading(true);

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        setError(null);
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          window.open(fileURL);
        });
      } else {
        message.error({
          content: `Hubo un error`,
        });
      }
      setLoading(false);
    });
  };

  const peticionHttpListadoCierres = () => {
    setListadoCierres(null);
    setLoading(true);
    let url = URL_BASE_API + "/cierres-rutas/pdf/cierre-diario";
    helpHttp()
      .get(url)
      .then((res) => {
        if (!res.err) {
          setError(null);
          setListadoCierres(res);
        } else {
          message.error({
            content: `Hubo un error`,
          });
        }
        setLoading(false);
      });
  };

  return (
    <div {...props}>
      <GroupHeader category="Cierres" busqueda={false} />
      <ModalPdfCierre
        show={modalPdfCierreVisible}
        opacity="0.8"
        w={"20em"}
        closeModal={closeModalPdfCierre}
        listadoCierres={listadoCierres}
        handleClickVerPdf={handleClickVerPdf}
      ></ModalPdfCierre>
      <GroupTableContainer>
        <GroupTableHeader
          section={hoy.toLocaleDateString()}
          enrutar={true}
          sectionEnrutar="PDF Cierre"
          handleEnrutar={handlePdfCierre}
        />
        <GroupFormContainer
          totalMora={totalMora}
          totalCobro={totalCobro}
          totalGastos={totalGastos}
          totalCreditos={totalCreditos}
          totalDevoluciones={totalDevoluciones}
          totalSaldados={totalSaldados}
          cajaInicial={cajaInicial}
          fechaCajaInicial={fechaCajaInicial}
          ajuste={ajuste}
          caja={caja}
          handleAplicarCierre={handleAplicarCierre}
          handleVerPdfMora={handleVerPdfMora}
          disabled={true}
        />
      </GroupTableContainer>
      {error && (
        <Mensaje
          mensaje={`Error ${error.status}: ${error.statusText}`}
          type="error"
          title="Error"
        />
      )}
      {loading && <Loader />}
      <NavBar view="cierres" />
    </div>
  );
};

export default CierresView;
