import React from "react";

const ErrorInput = ({ textoError }) => {
  return (
    <div className="ant-form-item-explain">
      <div
        role="alert"
        className="ant-form-item-explain-error"
        style={{ color: "#ff4d4f", fontSize: "0.8em" }}
      >
        {textoError}
      </div>
    </div>
  );
};

export default ErrorInput;
