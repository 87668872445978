import React from "react";
import { Redirect, Route, Switch } from "react-router";
import AbonosView from "../views/abonos/AbonosView";
import CierresView from "../views/cierres/CierresView";
import CreditosView from "../views/creditos/CreditosView";
import ErrorView from "../views/errores/ErrorView";
import GastosView from "../views/gastos/GastosView";
import VerAbonosView from "../views/verabonos/VerAbonosView";

const PortalRouter = () => {
  return (
    <>
      <div>
        <Switch>
          <Route
            exact
            path="/abonos"
            render={({ staticContext, ...props }) => <AbonosView {...props} />}
          />
          <Route
            exact
            path="/ver-abonos"
            render={({ staticContext, ...props }) => (
              <VerAbonosView {...props} />
            )}
          />
          <Route
            exact
            path="/gastos"
            render={({ staticContext, ...props }) => <GastosView {...props} />}
          />
          <Route
            exact
            path="/creditos"
            render={({ staticContext, ...props }) => (
              <CreditosView {...props} />
            )}
          />
          <Route
            exact
            path="/cierres"
            render={({ staticContext, ...props }) => <CierresView {...props} />}
          />

          <Route
            exact
            path="/error/:estado"
            render={({ staticContext, ...props }) => <ErrorView {...props} />}
          />

          <Redirect to="/abonos" />
        </Switch>
      </div>
    </>
  );
};

export default PortalRouter;
