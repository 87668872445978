import { types } from "../types/types";

export const creditosInitialState = {
  db: null,
  totalCreditos: 0,
  copiaDd: null,
};

export const creditosReducer = (state = {}, action) => {
  switch (action.type) {
    case types.creditos_todos: {
      return {
        ...state,
        db: action.payload.map((data) => data),
        copiaDb: action.payload.map((data) => data),
        totalCreditos: action.payload.reduce(
          (prevValue, currentValue) =>
            prevValue + parseInt(currentValue.capital),
          0
        ),
      };
    }

    case types.creditos_buscar: {
      let busqueda = action.payload.toLocaleLowerCase();
      let resultado = state.copiaDb.filter((item) => {
        if (
          item.cliente.nombre_completo.toLocaleLowerCase().includes(busqueda)
        ) {
          return item;
        }
      });
      return {
        ...state,
        db: resultado,
      };
    }

    case types.creditos_limpiar_filtro: {
      return {
        ...state,
        db: state.copiaDb,
      };
    }

    case types.creditos_eliminar: {
      return {
        ...state,
        db: state.db.filter((item) => item.id !== action.payload.id),
        totalCreditos: state.totalCreditos - action.payload.capital,
        copiaDb: state.copiaDb.filter((item) => item.id !== action.payload.id),
      };
    }

    case types.creditos_crear: {
      return {
        ...state,
        db: [action.payload, ...state.db],
        copiaDb: [action.payload, ...state.db],
        totalCreditos: state.totalCreditos + parseInt(action.payload.capital),
      };
    }

    default:
      return state;
  }
};
