import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`

    button{
        display: inline-block;
        width:${ofc.x1};
        height:${ofc.x1};
    }
`

export default styles
    