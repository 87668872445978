//Import
import React from "react";
import styled from "styled-components";
import styles from "./GroupTableHeader_styles";

// components
import TableHeaderContainer from "../TableHeaderContainer";
import ButtonTableHeader from "../ButtonTableHeader";
import TextTableHeader from "../TextTableHeader";
import ButtonTableHeaderEnrutar from "../ButtonTableHeaderEnrutar/ButtonTableHeaderEnrutar";

/**
 *
 * PROPS
 *  button:Boolean = Show the 'crear crédito' button if true it will block section prop
 *  section:String = Name from view section
 */

// Componente base
const GroupTableHeader_base = ({
  enrutar = false,
  handleCrear,
  section,
  button,
  handleEnrutar,
  sectionEnrutar,
  ...props
}) => {
  return (
    <div {...props}>
      <TableHeaderContainer>
        <TextTableHeader />
        {button ? (
          <ButtonTableHeader section={section} handleCrear={handleCrear} />
        ) : (
          <div className="section">{section}</div>
        )}
        {enrutar && (
          <ButtonTableHeaderEnrutar
            handleEnrutar={handleEnrutar}
            section={sectionEnrutar}
          />
        )}
      </TableHeaderContainer>
    </div>
  );
};
const GroupTableHeader = styled(GroupTableHeader_base)`
  ${styles}
`;
export default GroupTableHeader;
