import React from "react";
import { Alert } from "antd";

const Mensaje = ({ mensaje, type, title }) => {
  return (
    <>
      <Alert message={title} description={mensaje} type={type} showIcon />
    </>
  );
};

export default Mensaje;
