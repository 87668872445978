import { css } from 'styled-components'
import ofc from '../../settings/variables'

const styles = css`
    margin-bottom:${ofc.s3}em;
    .home-input{
        border-bottom:1px solid rgba(255,255,255,0.6);
        color:#fff;
        font-size:${ofc.x1};
        padding:${ofc.s3}em ${ofc.s4}em;


        &::placeholder{
            color:#fff;
            opacity:0.5;/* Firefox */
        }
        &:focus{
            border-bottom-width:3px;
        }
    }
`

export default styles
    