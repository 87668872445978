//Import
import React from "react";
import styled from "styled-components";
import styles from "./HomeLogo_styles";

// Componente base
const HomeLogo_base = ({ ...props }) => {
  return (
    <div {...props}>
      <img src="./img/logo.png" alt="Ofinec - Control Operativo Financiero" />
    </div>
  );
};
const HomeLogo = styled(HomeLogo_base)`
  ${styles}
`;
export default HomeLogo;
