//Import
import React from "react";
import styled from "styled-components";
import styles from "./IconNav_styles";

/**
 * icon:String Sets Icon -> Options: abonos, verabonos, gastos, creditos, cierres, info
 * text:String Sets Caption texts
 * selected:Boolean Sets component selected class
 * to:String Sets View Route
 * size:String sets Icon size (Optional)
 * fontsize:String sets Font caption size (Optional)
 */

// Componente base
const IconNav_base = ({
  icon,
  text,
  selected,
  to,
  size,
  fontsize,
  handleClickDetalle,
  ...props
}) => {
  return (
    <div {...props}>
      <button
        onClick={handleClickDetalle}
        className={`transparent-button ${selected && "selected"}`}
      >
        <span className={"iconContainer " + icon}></span>
        <span className="caption">{text}</span>
      </button>
    </div>
  );
};
const IconNav = styled(IconNav_base)`
  ${styles}
`;
export default IconNav;
