//Import
import React from "react";
import styled from "styled-components";
import styles from "./RowText_styles";

/***
 * PROPS
 * username:String = Set Username on item
 * debt:String Set debt on Saldo
 *
 */

// Componente base
const RowText_base = ({
  mostrarSaldo = false,
  handleClickDetalle,
  username,
  debt,
  fechaCredito,
  prestado,
  aldia,
  enrutar = null,
  ...props
}) => {
  return (
    <div {...props} onClick={handleClickDetalle}>
      <div className="username">{username}</div>
      {debt ? (
        <>
          <div className="debt">
            {mostrarSaldo ? <b>Saldo</b> : ""} {debt}
          </div>
          <div className="debt">
            <b>Al día</b> : {aldia}
          </div>
          <div className="debt">
            <b>Prestado</b> : {prestado}
          </div>
          <div className="debt">
            <b>Fecha</b> : {fechaCredito}
          </div>
        </>
      ) : (
        ""
      )}
      {enrutar ? <div style={{ fontSize: "1.2em" }}>{enrutar}</div> : ""}
    </div>
  );
};
const RowText = styled(RowText_base)`
  ${styles}
`;
export default RowText;
