
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './ContextPanel_styles'

/***
 * PROPS
 * top:String (sufix:px, pt, em, ex, vh ...) Set panel top position 
 * left:String (sufix:px, pt, em, ex, vh ...) Set panel left position 
 * right:String (sufix:px, pt, em, ex, vh ...) Set panel right position 
 * bottom:String (sufix:px, pt, em, ex, vh ...) Set panel bottom position 
 * visible:Boolean = shows panel
 *      
 */


// Componente base
const ContextPanel_base = ({top, left, right, bottom, visible, ...props}) => {
    return (
        <div {...props}>
            <div className="itemsContainer">
                { props.children }
            </div>
        </div>
    )
}
const ContextPanel = styled(ContextPanel_base)`${ styles }`
export default ContextPanel
