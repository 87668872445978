import { css } from 'styled-components'
import ofc from '../../settings/variables'

const styles = css`
    background-color: ${ofc.primary};
    height:${ofc.headerh};
    position:fixed;
    width:100%;
    z-index:50;

`

export default styles
    