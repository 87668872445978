import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    button{
        color:${ofc.primary};
        font-weight:700;
        font-size:${ofc.x0};
    }

`

export default styles
    