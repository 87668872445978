import { css } from "styled-components";

const styles = css`
  img {
    margin: auto;
    width: 250px;
  }
`;

export default styles;
