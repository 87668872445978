//Import
import React, { useEffect, useReducer, useState } from "react";

//Layouts
import GroupHeader from "../../components/GroupHeader";
import GroupTableContainer from "../../components/GroupTableContainer";
import GroupItemGen from "../../components/GroupItemGen";
import GroupTableHeader from "../../components/GroupTableHeader";
import NavBar from "../../components/NavBar";

//vars
import ofc from "../../settings/variables";
import {
  gastosInitialState,
  gastosReducer,
} from "../../reducers/gastosReducer";
import { URL_BASE_API } from "../../settings/urlBaseApi";
import { types } from "../../types/types";
import Loader from "../../components/Loader/Loader";
import Mensaje from "../../components/Mensaje/Mensaje";
import { helpHttp } from "../../helpers/helpHtpp";

import { message } from "antd";
import { Modal as ModalAnt } from "antd";
import { formatNumber } from "../../helpers/formatNumber";
import ModalGasto from "../../components/ModalGasto";
import { useForm } from "../../hooks/useForm";

const validationsForm = (formValues) => {
  let errors = {};
  let regexNumeroEnteroMayorCero = /^[1-9]\d*$/;
  let regexTamano = /^.{3,120}$/;

  if (!formValues.categoria.trim()) {
    errors.categoria = "El campo 'Categoria' es requerido";
  } else if (!formValues.categoria.trim("DEFAULT")) {
    errors.categoria = "El campo 'Categoria' es requerido";
  }

  if (!formValues.gasto.trim()) {
    errors.gasto = "El campo 'Gasto' es requerido";
  } else if (!formValues.gasto.trim("DEFAULT")) {
    errors.gasto = "El campo 'Gasto' es requerido";
  }

  if (!formValues.descripcion.trim()) {
    errors.descripcion = "El campo 'Descripcion' es requerido";
  } else if (!regexTamano.test(formValues.descripcion.trim())) {
    errors.descripcion = "El campo 'Descripcion' debe tener mas de 3 letras";
  }

  if (!formValues.valor.trim()) {
    errors.valor = "El campo 'Valor' es requerido";
  } else if (!regexNumeroEnteroMayorCero.test(formValues.valor.trim())) {
    errors.valor = "El campo 'Valor' debe mayor a cero sin decimales";
  }

  return errors;
};

// Componente base
const GastosView = ({ ...props }) => {
  const [state, dispatch] = useReducer(gastosReducer, gastosInitialState);
  const { db, totalGastos } = state;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDetalleVisible, setModalDetalleVisible] = useState(false);
  const [dataModalDetalle, setDataModalDetalle] = useState("");
  const [obtenerCategoriasGastos, setObtenerCategoriasGastos] = useState(false);
  const [categoriasGastos, setCategoriasGastos] = useState(null);
  const [gastosPorCategoria, setGastosPorCategoria] = useState(null);

  const handleInputBlur = (e) => {
    handleInputChange(e);
    setErrorsForm(validationsForm(formValues));
  };
  const [formValues, handleInputChange, reset] = useForm({
    categoria: "",
    gasto: "",
    descripcion: "",
    valor: "",
  });
  const [errorsForm, setErrorsForm] = useState({});

  const handleClickDetalle = (el) => {
    setDataModalDetalle(el);
    setModalDetalleVisible(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModalVisible(false);
  };

  const closeModalDetalle = () => {
    setModalDetalleVisible(false);
  };

  const handleClickEliminar = (e, el) => {
    e.preventDefault();
    ModalAnt.confirm({
      title: `Eliminar gasto ${el.descripcion}`,
      content: `¿Está seguro de eliminar el gasto ${
        el.gasto_padre.nombre
      } por valor de ${formatNumber(el.valor)}`,
      cancelText: "Cancelar",
      okText: "Eliminar",
      onOk: () => eliminarGasto(el),
    });
  };

  const eliminarGasto = (el) => {
    let url = URL_BASE_API + "/gastos/" + el.id;
    setLoading(true);
    helpHttp()
      .del(url)
      .then((res) => {
        if (!res.err) {
          if (res instanceof TypeError) {
            message.error({
              content: `Gasto ${el.descripcion} no fue eliminado, intentelo de nuevo!`,
            });
          } else {
            setError(null);
            dispatch({
              type: types.gastos_eliminar,
              payload: el,
            });
            message.success({
              content: `Gasto ${el.descripcion} fue eliminado!`,
            });
          }
        } else {
          message.error({
            content: `Hubo un error, el gasto ${el.descripcion} no fue eliminado!`,
          });
        }
        setLoading(false);
      });
  };

  const handleCrear = () => {
    setModalVisible(true);
    if (!obtenerCategoriasGastos) {
      let url = URL_BASE_API + "/categorias-gastos";
      setLoading(true);
      helpHttp()
        .get(url)
        .then((res) => {
          if (!res.err) {
            setError(null);
            setCategoriasGastos(res);
            setObtenerCategoriasGastos(true);
          } else {
            message.error({
              content: `Hubo un error`,
            });
          }
          setLoading(false);
        });
    }
  };

  const handleSelectCategoria = (e) => {
    let url = URL_BASE_API + "/gastos-por-categorias/" + e.target.value;
    setLoading(true);
    helpHttp()
      .get(url)
      .then((res) => {
        if (!res.err) {
          setError(null);
          setGastosPorCategoria(res);
        } else {
          message.error({
            content: `Hubo un error`,
          });
        }
        setLoading(false);
      });
  };

  const handleCrearSubmit = (e) => {
    e.preventDefault();
    const erroresLocales = validationsForm(formValues);
    setErrorsForm(erroresLocales);

    if (Object.keys(erroresLocales).length === 0) {
      let url = URL_BASE_API + "/gastos";
      setLoading(true);
      helpHttp()
        .post(url, {
          body: formValues,
        })
        .then((res) => {
          if (!res.err) {
            if (res instanceof TypeError) {
              message.error({
                content: `El gasto no fue creado, intentelo de nuevo!`,
              });
            } else {
              setError(null);
              dispatch({
                type: types.gastos_crear,
                payload: res,
              });
              message.success({
                content: `Gasto ${res.descripcion} por valor ${res.valor} fue creado con éxito!`,
              });
              reset();
              e.target.reset();
            }
          } else {
            setError(res);
          }
          setLoading(false);
          setModalVisible(false);
        });
    } else {
      return;
    }
  };

  const filtroBusqueda = ({ target }) => {
    dispatch({
      type: types.gastos_buscar,
      payload: target.value,
    });
  };

  const limpiarFiltroBusqueda = () => {
    dispatch({
      type: types.gastos_limpiar_filtro,
    });
  };

  let url = URL_BASE_API + "/gastos";
  useEffect(() => {
    setLoading(true);
    helpHttp()
      .get(url)
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null);
            dispatch({
              type: types.gastos_todos,
              payload: res,
            });
          } else {
            setError(res);
          }
        }
        setLoading(false);
      });
  }, [url]);

  return (
    <div {...props}>
      <GroupHeader
        filtroBusqueda={filtroBusqueda}
        category="Gastos"
        limpiarFiltroBusqueda={limpiarFiltroBusqueda}
      />
      <ModalGasto
        type="bottom"
        show={modalDetalleVisible}
        opacity="0.8"
        w={"15em"}
        closeModalDetalle={closeModalDetalle}
        dataModalDetalle={dataModalDetalle}
      ></ModalGasto>
      <ModalGasto
        type="centered"
        show={modalVisible}
        opacity="0.8"
        w={"18em"}
        closeModal={closeModal}
        handleCrearSubmit={handleCrearSubmit}
        categoriasGastos={categoriasGastos}
        handleSelectCategoria={handleSelectCategoria}
        gastosPorCategoria={gastosPorCategoria}
        handleInputChange={handleInputChange}
        handleInputBlur={handleInputBlur}
        errorsForm={errorsForm}
      ></ModalGasto>
      <GroupTableContainer>
        <GroupTableHeader
          section="Gasto"
          button={true}
          handleCrear={handleCrear}
        />
        {db !== null ? (
          db.map((el) => (
            <GroupItemGen
              key={el.id}
              username={el.descripcion}
              userdebt={el.gasto_padre.nombre}
              uservalue={formatNumber(el.valor)}
              iconcolor={ofc.primary}
              valuecolor="red"
              icon="info"
              texto="Detalle"
              handleClickDetalle={(e) => handleClickDetalle(el)}
              handleClickEliminar={(e) => handleClickEliminar(e, el)}
              textoEliminar={`Gasto ${el.descripcion}`}
            />
          ))
        ) : (
          <Mensaje
            mensaje="No hay información para mostrar"
            type="info"
            title="Información"
          />
        )}
        {error && (
          <Mensaje
            mensaje={`Error ${error.status}: ${error.statusText}`}
            type="error"
            title="Error"
          />
        )}
      </GroupTableContainer>
      {loading && <Loader />}
      <NavBar
        texto1="No. Gastos"
        texto2="Total Gastos"
        totalTexto1={db != null ? db.length : 0}
        totalTexto2={formatNumber(totalGastos)}
        view="gastos"
      />
    </div>
  );
};
export default GastosView;
