import { css } from "styled-components";
import ofc from "../../settings/variables";

const styles = css`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 57px;
  background-color: ${ofc.primary};
`;

export default styles;
