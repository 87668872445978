import { css } from "styled-components";
import ofc from "../../settings/variables";
const styles = css`
  color: ${ofc.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  overflow-y: auto;
`;

export default styles;
