import { createGlobalStyle } from "styled-components";
import ofc from "./variables";

const GlobalStyle = createGlobalStyle`

    // Clearing basic sizes [OPTIONAL]
    
    *{
        margin: 0;
        padding: 0;
        box-sizing:border-box;

    }
    
    // Setting main tags as a block

    body,html{
        width:100%;
        height: 100%;

    }

    // Setting main fonts & sizes

    body{        
        font-family:${ofc.main};
        font-size: ${ofc.font_size};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height:1;
        background-color:${ofc.bgLight};
    }

    // Setting basic configuration to img tags

    figure{
        margin:0;
    }

    img{
        max-width:100%;
        height:auto;
        display:block;
    }

    //Setting container size for fullscreen and mobile viewports

    #root {
        width:100%;
        height:100%;
    }

    // Setting all transparent buttons
    .transparent-button{
        border:none;
        outline:none;
        background-color: transparent;
    }

    // profile avatar empty
    .empty{
        width:100%;
        height:100%;
        background-color: ${ofc.bgDark};
    }

    // Input base cleaner
    .transparent-input{
        border:none;
        background-color:transparent;
        display:block;
        width:100%;
        &:focus, &:active{
            border:none;
            outline:none;
        }
    }

`;

export default GlobalStyle;
