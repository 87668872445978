//Import
import React from "react";
import styled from "styled-components";
import styles from "./ModalPdfCierre_styles";
//Components
import ModalHeader from "../ModalHeader";
import ModalContent from "../ModalContent";
import ModalBottom from "../ModalBottom";

import { ICol, IRow } from "../Grid";
import ItemContainer from "../ItemContainer";
import RowText from "../RowText";

// Componente base
const ModalPdfCierre_base = ({
  listadoCierres,
  handleClickVerPdf,
  closeModal,
  show,
  w,
  opacity,
  ...props
}) => {
  // Render
  return (
    <div {...props}>
      <ModalBottom style={{ overflow: "auto", maxHeight: "90vh" }}>
        <ModalHeader icon="" noclose closeModalDetalle={closeModal}>
          PDF cierre diario
        </ModalHeader>
        <ModalContent>
          {listadoCierres !== null
            ? listadoCierres.map((cierre) => (
                <ItemContainer key={cierre.id}>
                  <IRow valign="center" justify={"center"} py={1} px={0.1}>
                    <ICol w={47}>
                      <RowText enrutar={cierre.fecha} />
                    </ICol>
                    <ICol wf={8}>
                      <div
                        style={{ color: "#5CBC96" }}
                        onClick={(e) => handleClickVerPdf(e, cierre)}
                      >
                        <strong>Ver PDF</strong>
                      </div>
                    </ICol>
                  </IRow>
                </ItemContainer>
              ))
            : ""}
        </ModalContent>
      </ModalBottom>
    </div>
  );
};
const ModalEnrutar = styled(ModalPdfCierre_base)`
  ${styles}
`;
export default ModalEnrutar;
