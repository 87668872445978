
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './HeaderContainerStyles'

// Componente base
const HeaderContainer_base = ({...props}) => {
    return (
        <div {...props}>
            { props.children }
        </div>
    )
}
const HeaderContainer = styled(HeaderContainer_base)`${ styles }`
export default HeaderContainer
