//Import
import React from "react";
import styled from "styled-components";
import styles from "./ItemValue_styles";

/***
 * PROPS
 * color:String (green, red or blue ) = Define item text color
 */

// Componente base
const ItemValue_base = ({ color, ...props }) => {
  return <div {...props}>{props.children}</div>;
};
const ItemValue = styled(ItemValue_base)`
  ${styles}
`;
export default ItemValue;
