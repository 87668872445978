//Import
import React from "react";
import styled from "styled-components";
import { ICol, IRow } from "../Grid";
import styles from "./DayProgress_styles";

/***
 * PROPS
 * acumulado:String/Number Sets value from acumulado item
 * meta:String/Number Sets value from "Meta del día" Item
 */

// Componente base
const DayProgress_base = ({
  texto1 = null,
  texto2 = null,
  totalTexto1,
  totalTexto2,
  acumulado,
  meta,
  ...props
}) => {
  return (
    <div {...props}>
      <IRow py={0.4}>
        {texto1 && (
          <ICol w={50} className="showarrow">
            <span className="title">{texto1}</span>
            <span className="value"> {totalTexto1} </span>
          </ICol>
        )}
        {texto2 && (
          <ICol w={50}>
            <span className="title"> {texto2} </span>
            <span className="value goal"> {totalTexto2} </span>
          </ICol>
        )}
      </IRow>
    </div>
  );
};
const DayProgress = styled(DayProgress_base)`
  ${styles}
`;
export default DayProgress;
