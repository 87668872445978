import { css } from 'styled-components'

const styles = css`
    background-color: #fff;
    position:fixed;
    width:100%;
    bottom:0;
    z-index:99;
`

export default styles
    