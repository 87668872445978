//Import
import React from "react";
import styled from "styled-components";
import styles from "./FilledIconGps_styles";

/**
 *
 * Icon Names
 * bag
 * Props
 * color:String (hex value or rgba) Set icon bacground color
 */

// Componente base
const FilledIconGps_base = ({
  icon,
  color,
  handleClickIrUbicacion,
  ...props
}) => {
  return (
    <div {...props}>
      <div className={icon} onClick={handleClickIrUbicacion}></div>
    </div>
  );
};
const FilledIconGps = styled(FilledIconGps_base)`
  ${styles}
`;
export default FilledIconGps;
