//Import
import React from "react";
import styled from "styled-components";
import styles from "./FormInput_styles";

// Componente base
const FormInput_base = ({
  type = "text",
  formlabel,
  handleInputBlur,
  handleInputChange,
  name,
  ...props
}) => {
  return (
    <div {...props}>
      <label htmlFor={formlabel}>{formlabel}</label>
      <input
        type={type}
        name={name}
        className="FormInput  transparent-input"
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        required
        autoComplete="off"
      />
    </div>
  );
};
const FormInput = styled(FormInput_base)`
  ${styles}
`;
export default FormInput;
