
//Import
import React from 'react'
import styled from 'styled-components'
import { IRow } from '../Grid'
import styles from './TableHeaderContainer_styles'

// Componente base
const TableHeaderContainer_base = ({...props}) => {
    return (
        <div {...props}>
            <IRow px={1} py={0.8} justify="space-between" valign="center">

                    { props.children }
 
            </IRow>
        </div>
    )
}
const TableHeaderContainer = styled(TableHeaderContainer_base)`${ styles }`
export default TableHeaderContainer
