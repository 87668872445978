//Import
import React from "react";
import styled from "styled-components";
import styles from "./ItemDelete_styles";

// Componente base
const ItemDelete_base = ({ handleClickEliminar, textoEliminar, ...props }) => {
  return (
    <div {...props}>
      {
        /*<Popconfirm
        cancelText="Cancelar"
        okText="Si"
        onConfirm={handleClickEliminar}
        title={`Eliminar el ${textoEliminar}`}
        icon={<QuestionCircleOutlined style={{ color: "red" }} />} 
      >*/
        <button
          onClick={handleClickEliminar}
          className={"transparent-button delete-button"}
        >
          <img src="./img/svg/borrar.svg" alt="Borrar Item" />
        </button>
        /*</Popconfirm>*/
      }
    </div>
  );
};
const ItemDelete = styled(ItemDelete_base)`
  ${styles}
`;
export default ItemDelete;
