//Import
import React from "react";
import styled from "styled-components";
import styles from "./TextCategoryStyles";

// Props
/**
 * fontsize: String pixel or em format example: "16px" or "1em"
 * align: String (left, right, center, justify)
 */

// Componente base
const TextCategory_base = ({ align, fontsize, ...props }) => {
  return (
    <div {...props}>
      <b>{props.children}</b>
    </div>
  );
};
const TextCategory = styled(TextCategory_base)`
  ${styles}
`;
export default TextCategory;
