import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    display: ${props => props.visible ? 'inline-block': 'none'};
    position:fixed;
    top: ${props => props.top ? props.top : 'auto' };
    left: ${props => props.left ? props.left : 'auto' };
    bottom: ${props => props.bottom ? props.bottom : 'auto' };
    right: ${props => props.right ? props.right : 'auto' };
    z-index:90;
    background-color: #fff;
    padding:.5em 1em;
    color: ${ofc.textColor};
    font-size:${ofc.x0};
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
`

export default styles
    