import { useReducer, useEffect, useState } from "react";
import Globalstyle from "./settings/gstyle";
import styled from "styled-components";
import AppRouter from "./routers/AppRouter";
import "antd/dist/antd.css";
import { AuthContext } from "./context/AuthContext";
import { authReducer } from "./reducers/authReducer";
import { URL_BASE_API } from "./settings/urlBaseApi";
import Loader from "./components/Loader/Loader";
import { helpHttp, getToken, setToken } from "./helpers/helpHtpp";
import { message } from "antd";

const init = () => {
  return getToken() || { token: null };
};

message.config({
  top: 80,
  duration: 3,
});

function App() {
  const [token, dispatch] = useReducer(authReducer, {}, init);
  const [loading, setLoading] = useState(false);
  let url = URL_BASE_API + "/infouser";

  useEffect(() => {
    setToken(token);

    if (!getToken().token) {
      setLoading(false);
      return;
    }

    setLoading(true);
    helpHttp()
      .get(url)
      .then((res) => {
        setLoading(false);
      });
  }, [token, url]);

  return (
    <>
      <Globalstyle />
      <MainContainer>
        {loading && <Loader />}
        <AuthContext.Provider value={{ token, dispatch }}>
          <AppRouter />
        </AuthContext.Provider>
      </MainContainer>
    </>
  );
}

const MainContainer = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
`;

export default App;
