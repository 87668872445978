import { css } from "styled-components";
import ofc from "../../settings/variables";
const styles = css`
  padding: 0 ${ofc.s2}em;
  button {
    padding: ${ofc.s2}em 0;
  }
  span {
    color: ${ofc.textColor};
    display: block;
    &.iconContainer {
      width: ${(props) => props.size || ofc.x3};
      height: ${(props) => props.size || ofc.x3};
      margin: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &.abonos {
        background-image: url(./img/svg/empty_bag.svg);
      }
      &.verabonos {
        background-image: url(./img/svg/money.svg);
      }
      &.gastos {
        background-image: url(./img/svg/cart.svg);
      }
      &.creditos {
        background-image: url(./img/svg/user.svg);
      }
      &.cierres {
        background-image: url(./img/svg/box.svg);
      }
      &.info {
        background-image: url(./img/svg/informe.svg);
      }
    }
    &.caption {
      font-size: ${(props) => props.fontsize || ofc.x0};
      text-align: center;
    }
  }

  button.selected {
    border-bottom: 2px solid ${ofc.primary};
    span.iconContainer {
      &.abonos {
        background-image: url(./img/svg/empty_bag_selected.svg);
      }
      &.verabonos {
        background-image: url(./img/svg/money_green.svg);
      }
      &.gastos {
        background-image: url(./img/svg/cart_selected.svg);
      }
      &.creditos {
        background-image: url(./img/svg/user_selected.svg);
      }
      &.cierres {
        background-image: url(./img/svg/box_selected.svg);
      }
      &.info {
        background-image: url(./img/svg/informe_selected.svg);
      }
    }
    span.caption {
      color: ${ofc.primary};
    }
  }
`;

export default styles;
