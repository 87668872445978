import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    font-weight:700;
    text-align:right;
    color:${ props => {
        switch(true) {
            case props.color === 'blue':
                return ofc.secondary
            case props.color === 'red':
                return ofc.red
            case props.color === 'green':
                return ofc.primary
            default:
                return ofc.primary
        } } }

`

export default styles
    