//Import
import React from "react";
import styled from "styled-components";
import styles from "./ModalButton_styles";

/**
 * type:String = (ok, cancel) Change the color
 **/

// Componente base
const ModalButtonCrear_base = ({
  handleCrearSubmit,
  type,
  cancel,
  ...props
}) => {
  return (
    <div {...props}>
      <button
        type="submit"
        className={`transparent-button ${
          cancel ? "cancel-button" : "ok-button"
        } `}
      >
        {props.children}
      </button>
    </div>
  );
};
const ModalButtonCrear = styled(ModalButtonCrear_base)`
  ${styles}
`;
export default ModalButtonCrear;
