import { css } from "styled-components";
import ofc from "../../settings/variables";
const styles = css`
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-radius: ${ofc.s2}em ${ofc.s2}em 0 0;
`;

export default styles;
