//Import
import React from "react";
import styled from "styled-components";
import styles from "./Modal_styles";

//Components
import ModalContainer from "../ModalContainer";
import ModalCentered from "../ModalCentered";
import ModalHeader from "../ModalHeader";
import ModalContent from "../ModalContent";
import ModalFooter from "../ModalFooter";
import FormInput from "../FormInput/FormInput";
import FormSelect from "../FormSelect/FormSelect";
import ModalBottom from "../ModalBottom";
import { formatNumber } from "../../helpers/formatNumber";
import FormSelectCategoria from "../FormSelectCategoria";
import ErrorInput from "../ErrorInput/ErrorInput";

/**
 *
 * Props:
 * type:String = Show modaltype 2 Options. "centered" "bottom"
 * show:Boolean = Modal Visibility
 * w:String = Modal width in css values
 * opacity:String / Number = Layout opacity values 0 to 1, 0.5 by default
 */

// Componente base
const ModalGasto_base = ({
  handleCrearSubmit,
  dataModal,
  dataModalDetalle,
  closeModal,
  closeModalDetalle,
  categoriasGastos,
  handleSelectCategoria,
  gastosPorCategoria,
  handleInputChange,
  handleInputBlur,
  errorsForm,
  show,
  type,
  w,
  opacity,
  ...props
}) => {
  //Modal type centered
  const modalCentered = () => {
    return (
      <ModalContainer>
        <ModalCentered w={w}>
          <form onSubmit={handleCrearSubmit}>
            <ModalHeader closeModal={closeModal} icon="">
              {" "}
              Crear Nuevo Gasto{" "}
            </ModalHeader>
            <ModalContent>
              <FormSelectCategoria
                name="categoria"
                formlabel="Categoria"
                categoriasGastos={categoriasGastos}
                handleSelectCategoria={handleSelectCategoria}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
              ></FormSelectCategoria>
              {errorsForm.categoria && (
                <ErrorInput textoError={errorsForm.categoria} />
              )}
              <FormSelect
                name="gasto"
                formlabel="Gasto"
                gastosPorCategoria={gastosPorCategoria}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
              ></FormSelect>
              {errorsForm.gasto && <ErrorInput textoError={errorsForm.gasto} />}
              <FormInput
                name="descripcion"
                formlabel="Descripción"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
              ></FormInput>
              {errorsForm.descripcion && (
                <ErrorInput textoError={errorsForm.descripcion} />
              )}
              <FormInput
                name="valor"
                formlabel="Valor"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="number"
              ></FormInput>
              {errorsForm.valor && <ErrorInput textoError={errorsForm.valor} />}
            </ModalContent>
            <ModalFooter closeModal={closeModal}></ModalFooter>
          </form>
        </ModalCentered>
      </ModalContainer>
    );
  };

  //Modal type bottom
  const modalBottom = () => {
    return (
      <ModalBottom>
        <ModalHeader icon="" noclose closeModalDetalle={closeModalDetalle}>
          {" "}
          Detalle Gasto{" "}
        </ModalHeader>
        <ModalContent>
          <p>
            Categoria:{" "}
            {dataModalDetalle !== "" ? dataModalDetalle.gasto_padre.nombre : ""}
          </p>
          <p>Nombre: {dataModalDetalle.descripcion}</p>
          <p>Valor: {formatNumber(dataModalDetalle.valor)}</p>
          <p>fecha: {dataModalDetalle.created_at}</p>
        </ModalContent>
      </ModalBottom>
    );
  };

  // Render
  return (
    <div {...props}>
      {type === "centered"
        ? modalCentered()
        : type === "bottom"
        ? modalBottom()
        : "No Modal rendered"}
    </div>
  );
};
const ModalGasto = styled(ModalGasto_base)`
  ${styles}
`;
export default ModalGasto;
