import { types } from "../types/types";

export const cierresRutasInitialState = {
  totalCobro: 1,
  totalGastos: 1,
  totalCreditos: 1,
  totalDevoluciones: 1,
  ajuste: 1,
  caja: 1,
  totalCierre: 1,
  totalSaldados: 1,
  cajaInicial: 1,
  fechaCajaInicial: "2000-00-00",
  totalMora: 1,
};

export const cierresRutasReducer = (state = {}, action) => {
  switch (action.type) {
    case types.cierres_rutas_todos: {
      return {
        ...state,
        totalCobro: action.payload[0].totalCobro,
        totalGastos: action.payload[0].totalGastos,
        totalCreditos: action.payload[0].totalCreditos,
        totalDevoluciones: action.payload[0].totalDevoluciones,
        ajuste: action.payload[0].ajuste,
        caja: action.payload[0].caja,
        totalSaldados: action.payload[0].totalSaldados,
        totalCierre: action.payload[0].totalCierre,
        cajaInicial: action.payload[0].cajaInicial,
        fechaCajaInicial: action.payload[0].fechaCajaInicial,
        totalMora: action.payload[0].totalMora,
      };
    }

    default:
      return state;
  }
};
