import { css } from "styled-components";
import ofc from "../../settings/variables";
const styles = css`
  color: ${ofc.textColor};
  .username {
    font-weight: 700;
    font-size: ${ofc.font_size};
  }
  .debt {
    font-size: ${ofc.x0};
  }
`;

export default styles;
