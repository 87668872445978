//Import
import React from "react";
import styled from "styled-components";
import styles from "./FormSelect_styles";

/**
 * Ant Design component props
 * https://ant.design/components/select/#Select-props
 *
 * Own Props
 * formLabel:String = shows label element if exist
 * */

// Componente base
const FormSelectCategoria_base = ({
  categoriasGastos = null,
  handleSelectCategoria,
  handleInputBlur,
  handleInputChange,
  formlabel,
  name,
  ...props
}) => {
  return (
    <div {...props}>
      <div className="ofc-select-label"> {formlabel} </div>
      <select
        onChange={(e) => {
          handleSelectCategoria(e);
          handleInputChange(e);
        }}
        className="select"
        onBlur={handleInputBlur}
        name={name}
        defaultValue={"DEFAULT"}
        required
      >
        <option disabled value="DEFAULT">
          Seleccione Categoría
        </option>
        {categoriasGastos !== null
          ? categoriasGastos.map((el) => (
              <option key={el.id} value={el.id}>
                {" "}
                {el.nombre}{" "}
              </option>
            ))
          : ""}
      </select>
    </div>
  );
};
const FormSelectCategoria = styled(FormSelectCategoria_base)`
  ${styles}
`;
export default FormSelectCategoria;
