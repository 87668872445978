//Import
import React from "react";
import styled from "styled-components";
//import ButtonCreate from '../ButtonCreate/ButtonCreate'
import { ICol } from "../Grid";
import styles from "./GroupTableContainer_styles";

// Componente base
const GroupTableContainer_base = ({ ...props }) => {
  return (
    <div {...props}>
      <ICol pb={9}>{props.children}</ICol>
    </div>
  );
};
const GroupTableContainer = styled(GroupTableContainer_base)`
  ${styles}
`;
export default GroupTableContainer;
