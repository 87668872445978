import { css } from "styled-components";
import ofc from "../../settings/variables";

const styles = css`
  background-color: #fff;
  width: ${(props) => props.w || "75%"};
  border-radius: ${ofc.s1}em;
`;

export default styles;
