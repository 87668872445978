//Import
import React from "react";
import styled from "styled-components";
import styles from "./ButtonTableHeaderEnrutar_styles";

// Componente base
const ButtonTableHeaderEnrutar_base = ({
  handleEnrutar,
  section = null,
  ...props
}) => {
  return (
    <div {...props}>
      <button onClick={handleEnrutar} className="transparent-button">
        {section} <span> + </span>
      </button>
    </div>
  );
};
const ButtonTableHeaderEnrutar = styled(ButtonTableHeaderEnrutar_base)`
  ${styles}
`;
export default ButtonTableHeaderEnrutar;
