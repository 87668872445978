import { css } from "styled-components";
import ofc from "../../settings/variables";

const styles = css`
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: ${ofc.s1}em;
  font-weight: 700;
  display: flex;
  align-items: center;

  .icon {
    display: inline-block;
    width: ${ofc.x3};
    height: ${ofc.x3};
    background-image: url(./img/svg/money_green.svg);
    background-size: 110%;
    margin-right: ${ofc.xm3};
    &.money {
      background-image: url(./img/svg/money.svg);
    }
    &.cart {
      background-image: url(./img/svg/cart_selected.svg);
    }
    &.gps {
      background-image: url(./img/svg/gps.svg);
    }
    &.box {
      background-image: url(./img/svg/box_selected.svg);
    }
    &.user {
      background-image: url(./img/svg/user.svg);
    }
  }

  .closebtn {
    position: absolute;
    right: ${ofc.x2};
    width: ${ofc.x0};
    height: ${ofc.x0};
  }
`;

export default styles;
