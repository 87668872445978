//Import
import React from "react";
import styled from "styled-components";
import { ICol, IRow } from "../Grid";
import MainFormButton from "../MainFormButton";
import OfcInput from "../OfcInput";
import styles from "./GroupFormContainer_styles";

// Componente base
const GroupFormContainer_base = ({
  totalMora,
  totalCobro,
  totalGastos,
  totalCreditos,
  caja,
  totalDevoluciones,
  totalSaldados,
  ajuste,
  cajaInicial,
  fechaCajaInicial,
  handleAplicarCierre,
  handleVerPdfMora,
  disabled,
  ...props
}) => {
  return (
    <div {...props}>
      <IRow w="78" align="center">
        <form action="">
          <ICol>
            <OfcInput
              value={totalMora + " - Ver PDF"}
              labelname={"TOTAL MORA: "}
              labelfor="totalmora"
              disabled={disabled}
              type="text"
              onClick={handleVerPdfMora}
            />
          </ICol>
          <ICol>
            <OfcInput
              value={cajaInicial}
              labelname={"Caja Inicial Fecha: " + fechaCajaInicial}
              labelfor="cajainicial"
              disabled={disabled}
            />
          </ICol>
          <ICol>
            <OfcInput
              value={totalCobro}
              labelname="Total Cobro"
              labelfor="totalcobro"
              disabled={disabled}
            />
          </ICol>

          <ICol>
            <OfcInput
              value={totalGastos}
              labelname="Total Gastos"
              labelfor="totalgastos"
              disabled={disabled}
            />
          </ICol>

          <ICol>
            <OfcInput
              value={totalCreditos}
              labelname="Total Créditos Nuevos"
              labelfor="totalcredito"
              disabled={disabled}
            />
          </ICol>

          <ICol>
            <OfcInput
              value={totalDevoluciones}
              labelname="Total Devoluciones"
              labelfor="totaldevoluciones"
              disabled={disabled}
            />
          </ICol>

          <ICol>
            <OfcInput
              value={totalSaldados}
              labelname="Total Saldados"
              labelfor="totalsaldados"
              disabled={disabled}
            />
          </ICol>

          <ICol>
            {/*<OfcInput
              value={ajuste}
              labelname="Ajuste"
              labelfor="ajuste"
              disabled={disabled}
            />*/}
          </ICol>

          <ICol>
            <OfcInput
              value={
                parseInt(totalDevoluciones) +
                parseInt(totalSaldados) +
                parseInt(totalCobro) -
                parseInt(totalGastos) -
                parseInt(totalCreditos)
              }
              labelname="Cierre del día"
              labelfor="cierredeldia"
              disabled={disabled}
            />
          </ICol>

          <ICol>
            <OfcInput
              value={caja}
              labelname="Caja Final"
              labelfor="cajafinal"
              disabled={disabled}
            />
          </ICol>

          <ICol pt="1" pb="3">
            <MainFormButton
              handleAplicarCierre={handleAplicarCierre}
              text="Aplicar"
            />
          </ICol>
        </form>
      </IRow>
    </div>
  );
};
const GroupFormContainer = styled(GroupFormContainer_base)`
  ${styles}
`;
export default GroupFormContainer;
