//Import
import React from "react";
import styled from "styled-components";
import styles from "./FormTextArea_styles";

// Componente base
const FormTextArea_base = ({
  formlabel,
  handleInputBlur,
  handleInputChange,
  name,
  ...props
}) => {
  return (
    <div {...props}>
      <label htmlFor={formlabel}>{formlabel}</label>
      <textarea
        name={name}
        className="FormInput  transparent-input"
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        required
        autoComplete="off"
      ></textarea>
    </div>
  );
};
const FormTextArea = styled(FormTextArea_base)`
  ${styles}
`;
export default FormTextArea;
