import { css } from "styled-components";
import ofc from "../../settings/variables";

const styles = css`
  figure {
    width: ${ofc.s};
    height: ${ofc.s};
    border-radius: 5%;
    overflow: hidden;
  }

  .mainAvatar {
    text-align: center;

    figure {
      width: ${ofc.m};
      height: ${ofc.m};
      display: block;
      margin: auto;
    }
    .avatarCaption {
      display: inline-block;
      font-size: ${ofc.xm1};
      position: relative;
      font-weight: 700;
      top: -${ofc.s2}em;
      background-color: #fff;
      color: ${ofc.greenDark};
      padding: 4px 12px;
      border-radius: 12px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

export default styles;
