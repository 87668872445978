//Import
import React from "react";
import styled from "styled-components";
import styles from "./FilledIconFunction_styles";

/**
 *
 * Icon Names
 * bag
 * Props
 * color:String (hex value or rgba) Set icon bacground color
 */

// Componente base
const FilledIconFunction_base = ({
  icon,
  color,
  handleClickActualizarUbicacionCredito,
  ...props
}) => {
  return (
    <div {...props} onClick={handleClickActualizarUbicacionCredito}>
      <div className={icon}></div>
    </div>
  );
};
const FilledIconFunction = styled(FilledIconFunction_base)`
  ${styles}
`;
export default FilledIconFunction;
