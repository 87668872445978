import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    button{
        color: ${ofc.primary};
        position:relative;
        font-weight:700;
        border:1px solid ${ofc.primary};
        padding: ${ofc.s1}em ${ofc.x3} ${ofc.s1}em ${ofc.s2}em ;
        border-radius: ${ofc.s1}em;
        span{
            position:absolute;
            top:0;
            right:${ofc.s1}em;
            display: inline-block;
            font-size:${ofc.x2};
        }

    }

`

export default styles
    