//Import
import React, { useState } from "react";
import styled from "styled-components";
import styles from "./SearchInput_styles";

// Componente base
const SearchInput_base = ({
  limpiarFiltroBusqueda,
  filtroBusqueda,
  ...props
}) => {
  const [hidden, setHidden] = useState(true);

  const openForm = () => {
    setHidden(false);
  };
  const closeForm = () => {
    setHidden(true);
    limpiarFiltroBusqueda();
  };

  return (
    <div {...props}>
      <button className="transparent-button" onClick={() => openForm()}>
        <img src="./img/svg/search.svg" alt="Buscar" />
      </button>
      <form action="" className={`search ${hidden ? "hidden" : "visible"}`}>
        <input
          type="text"
          onChange={filtroBusqueda}
          className="transparent-input searchInput"
          placeholder="Ingrese texto a buscar..."
        />

        <button
          type="reset"
          className="transparent-input closeButton"
          onClick={() => {
            closeForm();
          }}
        >
          <img src="./img/svg/close_light.svg" alt="" />
        </button>
      </form>
    </div>
  );
};
const SearchInput = styled(SearchInput_base)`
  ${styles}
`;
export default SearchInput;
