import { css } from "styled-components";
import ofc from "../../settings/variables";
const styles = css`
  width: ${ofc.x2};
  height: ${ofc.x2};
  & > div {
    border-radius: 50%;
  }
  .bag {
    background-image: url(./img/svg/bag_nobg.svg);
    background-size: contain;
    background-color: ${(props) => props.color || ofc.primary};
    width: 100%;
    height: 100%;
  }
  .gps {
    background-image: url(./img/gps.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: ${(props) => props.color || ofc.primary};
    width: 100%;
    height: 100%;
    background-position: center;
  }
`;

export default styles;
