import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
  text-align: center;
  button {
    border: 1px solid #fff;
    display: inline-block;
    color: #fff;
    padding: ${ofc.s4}em ${ofc.s5 + 1}em;
    border-radius: ${ofc.s2}em;
    font-size: ${ofc.font_size};
  }
`

export default styles
