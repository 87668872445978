//Import
import React, { useState } from "react";
import styled from "styled-components";
import styles from "./RowInput_styles";

/**
 * PROPS
 * submit:Boolean = Define if input behavior is based on submit or button
 * inputvalue:string = Sets Base Value to input type text
 */

// Componente base
const RowInput_base = ({
  handleClickRegistrarAbono,
  submit,
  inputvalue,
  textoAbono,
  ...props
}) => {
  const [val, newValue] = useState(inputvalue);

  return (
    <div {...props}>
      <input
        type="number"
        className="val"
        placeholder={val}
        //value={val}
        onChange={(event) => newValue(event.target.value)}
        autoComplete="off"
      />
      {
        <input
          onClick={(e) => handleClickRegistrarAbono(e, val)}
          className="submit transparent-button"
          type={submit ? "submit" : "button"}
          value=""
          autoComplete="off"
        />
      }
    </div>
  );
};
const RowInput = styled(RowInput_base)`
  ${styles}
`;
export default RowInput;
