import React from "react";
import styled from "styled-components";
import styles from "./Nav_styles";

//Components
import NavContainer from "../NavContainer";
import IconNav from "../IconNav";
//Progress
import GroupProgress from "../GroupProgress";
import NavProgressBar from "../NavProgressBar";
import DayProgress from "../DayProgress";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

/**
 * PROPS:
 * view:String = Shows selected menú from options
 * Options: abonos, verabonos, gastos, creditos, cierres
 *
 *
 * IMPORTANT: This components would be visible in app.js. Replace <NavContainer> to {props.children} and use it in views
 *
 */

// Componente base
const Nav_base = ({
  texto1,
  texto2,
  totalTexto1,
  totalTexto2,
  view,
  progreso = 100,
  ...props
}) => {
  const { pathname } = props.location;
  return (
    <div {...props}>
      <NavContainer>
        <GroupProgress>
          <NavProgressBar value={progreso} />
          <DayProgress
            texto1={texto1}
            texto2={texto2}
            totalTexto1={totalTexto1}
            totalTexto2={totalTexto2}
          />
        </GroupProgress>
        {pathname === "/abonos" ? (
          <IconNav
            icon="abonos"
            text="Abonos"
            to="#"
            selected={view === "abonos" && view}
          />
        ) : (
          <Link to="/abonos">
            <IconNav
              icon="abonos"
              text="Abonos"
              to="#"
              selected={view === "abonos" && view}
            />
          </Link>
        )}

        {pathname === "/ver-abonos" ? (
          <IconNav
            icon="verabonos"
            text="Ver Abonos"
            to="#"
            selected={view === "ver-abonos" && view}
          />
        ) : (
          <Link to="/ver-abonos">
            <IconNav
              icon="verabonos"
              text="Ver Abonos"
              to="#"
              selected={view === "ver-abonos" && view}
            />
          </Link>
        )}

        {pathname === "/gastos" ? (
          <IconNav
            icon="gastos"
            text="Gastos"
            to="#"
            selected={view === "gastos" && view}
          />
        ) : (
          <Link to="/gastos">
            <IconNav
              icon="gastos"
              text="Gastos"
              to="#"
              selected={view === "gastos" && view}
            />
          </Link>
        )}

        {pathname === "/creditos" ? (
          <IconNav
            icon="creditos"
            text="Créditos"
            to="#"
            selected={view === "creditos" && view}
          />
        ) : (
          <Link to="/creditos">
            <IconNav
              icon="creditos"
              text="Créditos"
              to="#"
              selected={view === "creditos" && view}
            />
          </Link>
        )}

        {pathname === "/cierres" ? (
          <IconNav
            icon="cierres"
            text="Cierres"
            to="#"
            selected={view === "cierres" && view}
          />
        ) : (
          <Link to="/cierres">
            <IconNav
              icon="cierres"
              text="Cierres"
              to="#"
              selected={view === "cierres" && view}
            />
          </Link>
        )}
      </NavContainer>
    </div>
  );
};
const NavBar = styled(Nav_base)`
  ${styles}
`;
export default withRouter(NavBar);
