
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './HomeContent_styles'

// Componente base
const HomeContent_base = ({...props}) => {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}
const HomeContent = styled(HomeContent_base)`${ styles }`
export default HomeContent
