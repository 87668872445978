//Import
import React from "react";
import styled from "styled-components";
import styles from "./ButtonTableHeader_styles";

// Componente base
const ButtonTableHeader_base = ({ handleCrear, section, ...props }) => {
  return (
    <div {...props}>
      <button onClick={handleCrear} className="transparent-button">
        Crear {section} <span> + </span>
      </button>
    </div>
  );
};
const ButtonTableHeader = styled(ButtonTableHeader_base)`
  ${styles}
`;
export default ButtonTableHeader;
