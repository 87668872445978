const TOKEN_KEY = "token";

export const helpHttp = () => {
  const customFetch = (endpoint, options) => {
    const token = getToken();

    if (token === null || token === "") {
      deleteToken();
      window.location = "/";
    }

    const defaultHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const authorizationHeader = {
      Authorization: token !== null ? `Bearer ${token.token}` : "",
    };

    const controller = new AbortController();
    options.signal = controller.signal;
    //options.mode = "no-cors";

    options.method = options.method || "GET";
    options.headers = options.headers
      ? token
        ? { ...defaultHeader, ...options.headers, ...authorizationHeader }
        : { ...defaultHeader, ...options.headers }
      : token
      ? { ...defaultHeader, ...authorizationHeader }
      : defaultHeader;

    options.body = JSON.stringify(options.body) || false;
    if (!options.body) delete options.body;

    setTimeout(() => controller.abort(), 30000);

    return fetch(endpoint, options)
      .then((res) =>
        res.ok
          ? res.json()
          : res.status === 420
          ? rutaCerrada()
          : res.status === 401
          ? sinPermisos()
          : Promise.reject({
              err: true,
              status: res.status || "00",
              statusText:
                res.json().catch((data) => data.message) || "Ocurrió un error",
            })
      )
      .catch((err) => err);
  };

  const rutaCerrada = () => {
    deleteToken();
    window.location = "/#/mensaje-error/ruta-cerrada";
    return;
  };

  const sinPermisos = () => {
    deleteToken();
    window.location = "/#/login";
    return;
  };

  const get = (url, options = {}) => customFetch(url, options);

  const post = (url, options = {}) => {
    options.method = "POST";
    return customFetch(url, options);
  };

  const put = (url, options = {}) => {
    options.method = "PUT";
    return customFetch(url, options);
  };

  const del = (url, options = {}) => {
    options.method = "DELETE";
    return customFetch(url, options);
  };

  return {
    get,
    post,
    put,
    del,
  };
};

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

export function getToken() {
  return JSON.parse(localStorage.getItem(TOKEN_KEY));
}

export function deleteToken() {
  //localStorage.removeItem(TOKEN_KEY);
  localStorage.setItem(TOKEN_KEY, null);
}
