
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './ButtonMenu_styles'

// Componente base
const ButtonMenu_base = ({...props}) => {
    return (
        <div {...props}>
            <button className="transparent-button">
                <img src="./img/svg/dots.svg" alt="Menú"/>
            </button>
        </div>
    )
}
const ButtonMenu = styled(ButtonMenu_base)`${ styles }`
export default ButtonMenu
