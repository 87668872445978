//Import
import React from "react";
import styled from "styled-components";
import styles from "./OfcInput_styles";

// Componente base
const OfcInput_base = ({
  disabled = false,
  value,
  labelname,
  labelfor,
  onClick,
  ...props
}) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div {...props} onClick={handleClick}>
      <div className="inputContainer">
        <div
          contentEditable={!disabled}
          className={`ofc-input ${disabled ? "disabled" : ""}`}
        >
          {value}
        </div>
        <label htmlFor={labelfor} className="label">
          {labelname}
        </label>
        <span className="bar"></span>
      </div>
    </div>
  );
};

const OfcInput = styled(OfcInput_base)`
  ${styles}
`;

export default OfcInput;
