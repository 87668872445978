//Import
import React from "react";
import styled from "styled-components";
import styles from "./RowTextCredito_styles";

/***
 * PROPS
 * username:String = Set Username on item
 * debt:String Set debt on Saldo
 *
 */

// Componente base
const RowText_base = ({ handleClickDetalle, username, debt, ...props }) => {
  return (
    <div {...props} onClick={handleClickDetalle}>
      <div className="username">{username}</div>
      <div className="debt">
        <b>Saldo</b> : {debt}
      </div>
    </div>
  );
};
const RowTextCredito = styled(RowText_base)`
  ${styles}
`;
export default RowTextCredito;
