//Import
import React from "react";

import styled from "styled-components";
import styles from "./MainFormButton_styles";

// External library
import { CheckCircleOutlined } from "@ant-design/icons";

/**
 * PROPS:
 * text: Button Text
 *  Icon Props ->  https://ant.design/components/icon/#Common-Icon
 * **/

// Componente base
const MainFormButton_base = ({ handleAplicarCierre, text, ...props }) => {
  return (
    <div {...props}>
      <button
        onClick={handleAplicarCierre}
        type="submit"
        className="transparent-button mfb-submit"
      >
        <span className="text">{text}</span>

        <CheckCircleOutlined />
      </button>
    </div>
  );
};
const MainFormButton = styled(MainFormButton_base)`
  ${styles}
`;
export default MainFormButton;
