const ofc = {
  // Colors:
  primary: "#1AB394",
  secondary: "#4E90E3",
  violet: "#7D55F1", //"#8663EC",
  red: "#E15969",
  textColor: "#5E5E5E",
  blueLight: "#74C7E1",
  greenLight: "#5CBC96",
  greenDark: "#3B5E62",
  yellow: "#FFC937",
  yellowLght: "#F9DF99",
  bgLight: "#F6F6F6",
  bgDark: "#BFBFBF",
  inputBorder: "#DBDBDB",
  // Fonts
  main: "'Roboto', sans-serif", // 400 & 700
  font_size: "16px",
  xm4: "0.375em",
  xm3: "0.5em",
  xm2: "0.625em",
  xm1: "0.75em", //12 px / 16
  x0: "0.875em", // 14px / 16
  x1: "1.1em",
  x2: "1.5em", //
  x3: "2em",
  x4: "3em",

  // Grid sizes
  s1: 0.3,
  s2: 0.6,
  s3: 1,
  s4: 1.2,
  s5: 1.5,

  // Header Height
  headerh: "57px",
  // Gradients
  gradient: (rot, col1, col2) => {
    return `linear-gradient(${rot},${col1},${col2})`;
  },

  // Icon Sizes
  xs: "2em", // 32 to 37px
  s: "3em", // 55px
  m: "4.8em", // 78px
};

export default ofc;
