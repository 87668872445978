
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './ModalContent_styles'

// Componente base
const ModalContent_base = ({...props}) => {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}
const ModalContent = styled(ModalContent_base)`${ styles }`
export default ModalContent
