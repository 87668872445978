import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    button{
//        width:24px;
        width:${ofc.x2};
    }
    .hidden{
        display:none;
    }
    .search {
        position: absolute;
        width:100%;
        top:0;
        left:0;
        .searchInput{
            background-color:${ofc.primary};
            height:${ofc.headerh};
            color:#fff;
            font-size:${ofc.x1};
            padding:${ofc.x1} 5em ${ofc.x1} ${ofc.x1};
            border-bottom: 2px solid ${ofc.yellow};
        }
        .submitInput{
            position:absolute;
            top:0;
            right:4.5em;
            bottom:0;
        }
        .closeButton{
            position:absolute;
            top:0;
            right:1.5em;
            bottom:0;
            width:1.2em;
        }
    }
`

export default styles
    