import { css } from 'styled-components'
import ofc from '../../settings/variables'

const styles = css`
    button {
        width:${ofc.x3};
        height:auto;
        display: inline-block;

        img{
            width:0.3em;
            display:block;
            margin:auto;
        }
    }

`

export default styles
    