import { css } from 'styled-components'
import ofc from '../../settings/variables'

const styles = css`
    font-weight: 700;
    color: #fff;
    font-size:${ofc.font_size};

`

export default styles
    