import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    position: relative;

    span{
        color: ${ofc.textColor};
        display: block;
        text-align: center;
        &.title{
            font-size:${ ofc.xm1 };
            padding: 0.2em 0;
        }
        &.value{
            font-weight:700;
            font-size:${ofc.x1};
            color:${ofc.primary};

            &.goal{
                color:${ofc.secondary};
                position: relative;
                //Arrow 
                &::before{
                    content:'';
                    position: absolute;
                    background-image:url(./img/svg/separator.svg);
                    width:1em;
                    height:1em;
                    background-size:contain;
                    left:-0.5em;
                    top:-0.5em;
                    
                }
            }
        }
    }
`

export default styles
    