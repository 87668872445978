//Import
import React from "react";
import styled from "styled-components";
import styles from "./ModalBottom_styles";

// Componente base
const ModalBottom_base = ({ ...props }) => {
  return <div {...props}>{props.children}</div>;
};
const ModalBottom = styled(ModalBottom_base)`
  ${styles}
`;
export default ModalBottom;
