//Import
import React from "react";
import styled from "styled-components";
import styles from "./HomeInput_styles";
/**
 * PROPS
 * val
 * placeholder
 * type:String Define input type from standar
 * val:String Sets Value on input
 * placeholder:String Sets placeholder into field
 *
 *
 */
// Componente base
const HomeInput_base = ({
  name,
  handleInputChange,
  handleInputBlur,
  value,
  placeholder,
  type,
  ...props
}) => {
  //const [value, NewValue] = useState(val)
  return (
    <div {...props}>
      <input
        name={name}
        className="home-input transparent-input"
        type={type ? type : "text"}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        autoComplete="off"
        onBlur={handleInputBlur}
        required
      />
    </div>
  );
};
const HomeInput = styled(HomeInput_base)`
  ${styles}
`;
export default HomeInput;
