import { css } from 'styled-components'
import ofc from '../../settings/variables'
const styles = css`
    .section{
        display: inline-block;
        font-weight:700;
        color: ${ofc.textColor};
        font-size:${ofc.x0};
    }
`

export default styles
    