//Import
import React from "react";
import styled from "styled-components";
import styles from "./Modal_styles";
import { Table } from "antd";
//Components
import ModalContainer from "../ModalContainer";
import ModalCentered from "../ModalCentered";
import ModalHeader from "../ModalHeader";
import ModalContent from "../ModalContent";
import ModalFooter from "../ModalFooter";
import FormInput from "../FormInput/FormInput";
import ModalBottom from "../ModalBottom";

// External components
import { Tabs } from "antd";
import { formatNumber } from "../../helpers/formatNumber";
import ErrorInput from "../ErrorInput/ErrorInput";
const { TabPane } = Tabs;
const callback = (key) => {
  //console.log(key);
};

/**
 *
 * Props:
 * type:String = Show modaltype 2 Options. "centered" "bottom"
 * show:Boolean = Modal Visibility
 * w:String = Modal width in css values
 * opacity:String / Number = Layout opacity values 0 to 1, 0.5 by default
 */

// Componente base
const Modal_base = ({
  dataModal,
  dataModalDetalle,
  dataModalDetalleAbonos,
  closeModal,
  closeModalDetalle,
  handleInputChange,
  handleInputBlur,
  errorsForm,
  closeModalCliente,
  handleCrearClienteSubmit,
  show,
  type,
  w,
  opacity,
  ...props
}) => {
  //Modal type centered
  const modalCentered = () => {
    return (
      <ModalContainer>
        <ModalCentered w={w}>
          <form onSubmit={handleCrearClienteSubmit}>
            <ModalHeader closeModal={closeModalCliente} icon="">
              {" "}
              Crear Cliente Nuevo{" "}
            </ModalHeader>
            <ModalContent>
              <FormInput
                name="documentoIdentificacion"
                formlabel="Documento de Identificación"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="number"
              ></FormInput>
              {errorsForm.documentoIdentificacion && (
                <ErrorInput textoError={errorsForm.documentoIdentificacion} />
              )}
              <FormInput
                name="nombreCompleto"
                formlabel="Nombre Completo"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="text"
              ></FormInput>
              {errorsForm.nombreCompleto && (
                <ErrorInput textoError={errorsForm.nombreCompleto} />
              )}
              <FormInput
                name="direccion"
                formlabel="Dirección"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="text"
              ></FormInput>
              {errorsForm.direccion && (
                <ErrorInput textoError={errorsForm.direccion} />
              )}
              <FormInput
                name="barrio"
                formlabel="Barrio"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="text"
              ></FormInput>
              {errorsForm.barrio && (
                <ErrorInput textoError={errorsForm.barrio} />
              )}
              <FormInput
                name="celular"
                formlabel="Celular"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="number"
              ></FormInput>
              {errorsForm.celular && (
                <ErrorInput textoError={errorsForm.celular} />
              )}
            </ModalContent>
            <ModalFooter closeModal={closeModalCliente}></ModalFooter>
          </form>
        </ModalCentered>
      </ModalContainer>
    );
  };

  //Modal type bottom
  const modalBottom = () => {
    const colmuns = [
      {
        title: "Valor",
        dataIndex: "valor_cuota",
        render: (cuota) => formatNumber(cuota),
      },
      {
        title: "Saldo",
        dataIndex: "saldo_actual",
        render: (saldo) => formatNumber(saldo),
      },
      {
        title: "Fecha",
        dataIndex: "fecha",
      },
      {
        title: "Hora",
        dataIndex: "hora",
      },
    ];
    const data = [];
    return (
      <ModalBottom>
        <ModalHeader icon="" noclose closeModalDetalle={closeModalDetalle}>
          {" "}
          {dataModalDetalle !== ""
            ? dataModalDetalle.cliente.nombre_completo
            : ""}{" "}
        </ModalHeader>
        <ModalContent>
          <Tabs defaultActiveKey="1" onChange={callback} className="ow-tabs">
            <TabPane tab="Pago" key="1">
              {dataModalDetalle !== "" ? (
                <>
                  <Table
                    columns={colmuns}
                    dataSource={data}
                    pagination={false}
                    rowKey="id"
                  >
                    {dataModalDetalleAbonos !== ""
                      ? dataModalDetalleAbonos.map((abono) => {
                          if (parseInt(abono.valor_cuota) !== 0) {
                            data.push(abono);
                          }
                          return "";
                        })
                      : ""}
                  </Table>
                </>
              ) : (
                ""
              )}
            </TabPane>
            <TabPane tab="Info General" key="2">
              {dataModalDetalle !== "" ? (
                <>
                  <p>
                    <strong>Fecha Creación:</strong>{" "}
                    {dataModalDetalle.credito.fecha}
                  </p>
                  <p>
                    <strong>Valor: </strong>{" "}
                    {formatNumber(dataModalDetalle.credito.capital)}
                  </p>
                  <p>
                    <strong>Saldo:</strong>{" "}
                    {formatNumber(dataModalDetalle.credito.saldo_actual)}
                  </p>
                  <p>
                    <strong>Valor Cuota: </strong>{" "}
                    {formatNumber(
                      Math.round(
                        dataModalDetalle.valor_cuota -
                          dataModalDetalle.valor_cuota_mora ===
                          0
                          ? dataModalDetalle.valor_cuota
                          : dataModalDetalle.valor_cuota -
                              dataModalDetalle.valor_cuota_mora
                      )
                    )}
                  </p>
                  {/*<p>
                    <strong>Forma Pago: </strong>
                    {dataModalDetalle.credito.forma_pago.nombre}
                  </p>*/}
                  <p>
                    <strong>Interés: </strong>{" "}
                    {dataModalDetalle.credito.interes / 10} %
                  </p>
                  <p>
                    <strong>Calificación: </strong>
                    {dataModalDetalle.cliente.calificacion}
                  </p>
                  <p>
                    <strong>Dirección: </strong>
                    {dataModalDetalle.cliente.direccion}
                  </p>
                  <p>
                    <strong>Teléfono: </strong>
                    {dataModalDetalle.cliente.celular_1}
                  </p>
                  <p>
                    <strong>Documento: </strong>
                    {dataModalDetalle.cliente.documento_identificacion}
                  </p>
                  <p>
                    <strong>Descripción: </strong>
                    {dataModalDetalle.credito.descripcion}
                  </p>
                </>
              ) : (
                ""
              )}
            </TabPane>
          </Tabs>
        </ModalContent>
      </ModalBottom>
    );
  };

  // Render
  return (
    <div {...props}>
      {type === "centered"
        ? modalCentered()
        : type === "bottom"
        ? modalBottom()
        : "No Modal rendered"}
    </div>
  );
};
const Modal = styled(Modal_base)`
  ${styles}
`;
export default Modal;
