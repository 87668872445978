
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './ItemContainer_styles'

// Componente base
const ItemContainer_base = ({...props}) => {
    return (
        <div {...props}>
            { props.children }
        </div>
    )
}
const ItemContainer = styled(ItemContainer_base)`${ styles }`
export default ItemContainer
