import { css } from 'styled-components'

const styles = css`
    background-color: #fff;
    height:100%;

    form{
        width:100%;
    }

`


export default styles
    