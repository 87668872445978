
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './TextHeaderStyles'

// Componente base
const TextHeader_base = ({...props}) => {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}
const TextHeader = styled(TextHeader_base)`${ styles }`
export default TextHeader
