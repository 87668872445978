//Import
import React from "react";
import styled from "styled-components";
import styles from "./ModalButton_styles";

/**
 * type:String = (ok, cancel) Change the color
 **/

// Componente base
const ModalButton_base = ({ closeModal, type, cancel, ...props }) => {
  return (
    <div {...props}>
      <button
        type="reset"
        onClick={closeModal}
        className={`transparent-button ${
          cancel ? "cancel-button" : "ok-button"
        } `}
      >
        {props.children}
      </button>
    </div>
  );
};
const ModalButton = styled(ModalButton_base)`
  ${styles}
`;
export default ModalButton;
