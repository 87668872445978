//Import
import React, { useState, useContext } from "react";
import styled from "styled-components";
import styles from "./GroupHeader_styles";

// Showing components
import Avatar from "../Avatar";
import HeaderContainer from "../HeaderContainer";
import TextHeader from "../TextHeader";
import TextCategory from "../TextCategory";
import ButtonMenu from "../ButtonMenu";
import SearchInput from "../SearchInput/SearchInput";

import { ICol, IRow } from "../Grid";
import ofc from "../../settings/variables";

// Context menu
import ContextPanel from "../ContextPanel";
import ContextItem from "../ContextItem";

// Antd icons
import { CloseCircleOutlined } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { types } from "../../types/types";
import { useHistory } from "react-router";
// antd Components

// Simple:Boolean Show a simple header (Just title)
// Title:String Show header title

// Componente base
const GroupHeader_base = ({
  filtroBusqueda,
  limpiarFiltroBusqueda,
  busqueda = true,
  simple,
  category,
  ...props
}) => {
  // Temp Vars
  const _image = "./img/o.png";
  const fecha = new Date().toLocaleDateString();
  const _category = category + " - " + fecha;
  const _title = "Ruta " + localStorage.getItem("ruta");

  const [contextVisible, setContextVisible] = useState(false);

  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const handleLogout = () => {
    dispatch({
      type: types.logout,
    });
    history.replace("/login");
  };

  const headerType = (isSimple) => {
    if (isSimple) {
      return (
        <IRow
          valign="center"
          py={ofc.s1}
          px={ofc.s2}
          justify="space-between"
          h="100"
        ></IRow>
      );
    } else {
      return (
        <IRow
          valign="center"
          py={ofc.s1}
          px={ofc.s2}
          justify="flex-start"
          h="100"
        >
          <ICol w={18}>
            <Avatar img={_image}></Avatar>
          </ICol>
          <ICol w={60}>
            <TextCategory>{_category}</TextCategory>
            <TextHeader> {_title} </TextHeader>
          </ICol>
          <ICol w={12}>
            {busqueda && (
              <SearchInput
                filtroBusqueda={filtroBusqueda}
                limpiarFiltroBusqueda={limpiarFiltroBusqueda}
              ></SearchInput>
            )}
          </ICol>
          <ICol w={10}>
            <ButtonMenu
              onClick={() => setContextVisible(!contextVisible)}
            ></ButtonMenu>
          </ICol>
        </IRow>
      );
    }
  };

  return (
    <>
      <HeaderContainer>
        {headerType(simple)}
        <ContextPanel right="0" visible={contextVisible}>
          <ContextItem onClick={handleLogout}>
            {" "}
            <CloseCircleOutlined /> Cerrar Sesión{" "}
          </ContextItem>
        </ContextPanel>
      </HeaderContainer>
    </>
  );
};
const GroupHeader = styled(GroupHeader_base)`
  ${styles}
`;
export default GroupHeader;
