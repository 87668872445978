//Import
import React from "react";
import styled from "styled-components";
import styles from "./FormSelect_styles";

/**
 * Ant Design component props
 * https://ant.design/components/select/#Select-props
 *
 * Own Props
 * formLabel:String = shows label element if exist
 * */

// Componente base
const FormSelect_base = ({
  gastosPorCategoria = null,
  formasPagos = null,
  creditosEnrutados = null,
  handleInputBlur,
  handleInputChange,
  formlabel,
  name,
  generarCuotasHoy = null,
  ...props
}) => {
  return (
    <div {...props}>
      <div className="ofc-select-label"> {formlabel} </div>
      <select
        className="select"
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        name={name}
        defaultValue={"DEFAULT"}
        required
      >
        <option disabled value="DEFAULT">
          Seleccione {formlabel}
        </option>
        {gastosPorCategoria !== null
          ? gastosPorCategoria.map((el) => (
              <option key={el.id} value={el.id}>
                {" "}
                {el.nombre}{" "}
              </option>
            ))
          : ""}
        {formasPagos !== null
          ? formasPagos.map((el) => (
              <option key={el.id} value={el.id}>
                {" "}
                {el.nombre}
              </option>
            ))
          : ""}
        {creditosEnrutados !== null ? (
          <option key={0} value={1}>
            Al inicio
          </option>
        ) : (
          ""
        )}
        {creditosEnrutados !== null
          ? creditosEnrutados.map((el) => (
              <option key={el.id} value={el.prioridad}>
                {" "}
                {el.prioridad} {el.cliente.nombre_completo}
              </option>
            ))
          : ""}
        {generarCuotasHoy !== null
          ? generarCuotasHoy.map((el) => (
              <option key={el.id} value={el.valor}>
                {" "}
                {el.nombre}
              </option>
            ))
          : ""}
      </select>
    </div>
  );
};
const FormSelect = styled(FormSelect_base)`
  ${styles}
`;
export default FormSelect;
