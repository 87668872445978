import { css } from 'styled-components'
import ofc from '../../settings/variables'

const styles = css`
    background-image: linear-gradient(${0},rgb(95,154,180),${ofc.primary});
    display: inline-block;
    color: #fff;
    border-radius:${ofc.s1}em;
    padding:1.1em 1em 1.1em 1em;
    .text{
        display: inline-block;
        padding-right:1.2em;
    }


`

export default styles
    