
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './ContextItem_styles'

// Componente base
const ContextItem_base = ({...props}) => {
    return (
        <div {...props}>
            <div>
                {props.children}
            </div>
        </div>
    )
}
const ContextItem = styled(ContextItem_base)`${ styles }`
export default ContextItem
