
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './TextTableHeader_styles'

// Componente base
const TextTableHeader_base = ({...props}) => {
    return (
        <div {...props}>
            <button className="transparent-button">
                
                Nombre
                <span className='Arrow'></span>

            </button>
        </div>
    )
}
const TextTableHeader = styled(TextTableHeader_base)`${ styles }`
export default TextTableHeader
