
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './NavProgressBar_styles'

// Componente base
const NavProgressBar_base = ({...props}) => {
    return (
        <div {...props}>
            <span className='bar'></span>
        </div>
    )
}
const NavProgressBar = styled(NavProgressBar_base)`${ styles }`
export default NavProgressBar
