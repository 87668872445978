import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import ErrorView from '../views/errores/ErrorView'
import LoginView from '../views/login/LoginView'
import PortalRouter from './PortalRouter'
import PrivateRoute from './PrivateRoute'

const AppRouter = () => {
  const { token } = useContext(AuthContext)
  const isToken = token.token === null ? false : true
  const cierresPendientes = true

  return (
    <HashRouter>
      <div>
        <Switch>
          <Route
            exact
            path="/mensaje-error/:estado"
            render={({ staticContext, ...props }) => <ErrorView {...props} />}
          />
          {!isToken && (
            <Route
              exact
              path="/login"
              render={({ staticContext, ...props }) => (
                <LoginView {...props} cierresPendientes={cierresPendientes} />
              )}
            />
          )}
          <PrivateRoute path="/" component={PortalRouter} isToken={isToken} />
        </Switch>
      </div>
    </HashRouter>
  )
}

export default AppRouter
