
//Import
import React from 'react'
import styled from 'styled-components'
import styles from './ModalCentered_styles'



/** 
 * Props:
 * w:String/Number = defines content width
 * 
*/

// Componente base
const ModalCentered_base = ({w, ...props}) => {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}
const ModalCentered = styled(ModalCentered_base)`${ styles }`
export default ModalCentered
