//Import
import React from "react";
import styled from "styled-components";
import styles from "./ModalHeader_styles";

/***
 * PROPS:
 * icon:String = Options = money, cart, gps, box, user
 * noclose:Boolean = shows close button
 */

// Componente base
const ModalHeader_base = ({
  closeModal,
  closeModalDetalle,
  icon,
  noclose,
  ...props
}) => {
  return (
    <div {...props}>
      <span className={`icon ${icon}`}></span>
      {props.children}

      {!noclose ? (
        <button onClick={closeModal} className="transparent-button closebtn">
          <img src="./img/svg/close.svg" alt="" />
        </button>
      ) : (
        <button
          onClick={closeModalDetalle}
          className="transparent-button closebtn"
        >
          <img src="./img/svg/close.svg" alt="" />
        </button>
      )}
    </div>
  );
};
const ModalHeader = styled(ModalHeader_base)`
  ${styles}
`;
export default ModalHeader;
