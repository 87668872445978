//Import
import React from "react";
import styled from "styled-components";
import styles from "./FilledIcon_styles";

/**
 *
 * Icon Names
 * bag
 * Props
 * color:String (hex value or rgba) Set icon bacground color
 */

// Componente base
const FilledIcon_base = ({ icon, color, ...props }) => {
  return (
    <div {...props}>
      <div className={icon}></div>
    </div>
  );
};
const FilledIcon = styled(FilledIcon_base)`
  ${styles}
`;
export default FilledIcon;
