import { css } from "styled-components";
import ofc from "../../settings/variables";
const styles = css`
  .ofc-select-label {
    padding: 0.6em 0;
    font-size: ${ofc.x0};
    font-weight: bold;
  }
  .select {
    padding: 0.8em;
    width: 100%;
    margin: 0em 0;
    font-size: 1em;
    color: ${ofc.textColor};
    border-radius: ${ofc.s1}em;
    // overwritting ant Select component styles
    div.ant-select-selector {
      border-radius: ${ofc.s1}em;
      height: 3em;
      background-color: ${ofc.bgLight};
      border-color: ${ofc.bgDark};
      span {
        display: flex;
        align-items: center;
      }
    }
    //end overwrite
  }
`;

export default styles;
