//Import
import React from "react";
import styled from "styled-components";
import styles from "./ModalEnrutar_styles";
//Components
import ModalHeader from "../ModalHeader";
import ModalContent from "../ModalContent";
import ModalBottom from "../ModalBottom";

import { ICol, IRow } from "../Grid";
import ItemContainer from "../ItemContainer";
import RowText from "../RowText";
import RowInputEnrutar from "../RowInputEnrutar/RowInputEnrutar";

// Componente base
const ModalEnrutar_base = ({
  creditosEnrutados,
  handleClickEnrutarSave,
  closeModal,
  show,
  w,
  opacity,
  ...props
}) => {
  // Render
  return (
    <div {...props}>
      <ModalBottom style={{ overflow: "auto", maxHeight: "90vh" }}>
        <ModalHeader icon="" noclose closeModalDetalle={closeModal}>
          Enrutar Créditos
        </ModalHeader>
        <ModalContent>
          {creditosEnrutados !== null
            ? creditosEnrutados.map((cliente) => (
                <ItemContainer key={cliente.id}>
                  <IRow valign="center" justify={"center"} py={1} px={0.1}>
                    <ICol w={47}>
                      <RowText enrutar={cliente.cliente.nombre_completo} />
                    </ICol>
                    <ICol wf={8}>
                      <RowInputEnrutar
                        handleClickEnrutarSave={(e, prioridad, el) =>
                          handleClickEnrutarSave(e, prioridad, cliente)
                        }
                        inputvalue={cliente.prioridad}
                      ></RowInputEnrutar>
                    </ICol>
                  </IRow>
                </ItemContainer>
              ))
            : ""}
        </ModalContent>
      </ModalBottom>
    </div>
  );
};
const ModalEnrutar = styled(ModalEnrutar_base)`
  ${styles}
`;
export default ModalEnrutar;
